import './Cell.scss';
import useRender from '@hooks/useRender';

const Cell = ({ field, item, renderCondition, isSelected, children }) => {
  const { itemShouldRender } = useRender();
  return (
    <>
      {itemShouldRender(renderCondition, item) && (
        <div
          className={`cell${isSelected ? ' selected' : ''}`}
          onClick={() => {
            console.log(field);
            console.log(item);
            field.onChange(item.id);
            console.log('onchange done');
          }}
        >
          {children}
        </div>
      )}
    </>
  );
};

export { Cell };
