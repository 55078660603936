// hooks
import useRedux from '@hooks/useRedux';
import { useNavigate } from 'react-router-dom';
// components
import Button from '@components/ui/Buttons/Button/Button';
import IconButton from '@components/ui/Buttons/IconButton/IconButton';
import PriceCard from '@components/ui/Cards/PriceCard/PriceCard';
// services
import { createProject } from '@services/projects';
// utils
import { ACTIONS, DIALOG_TYPE } from '@utils/enums';
import { getProjectBody } from '@utils/db/functions';
// styles
import './Dialog.scss';
import dialogImg from '@assets/img/dialog.png';

const Dialog = ({ type, id, projectName, onClose, setCustomFormStep, setProjectSubmittedPageOpen }) => {
  const {
    getModals,
    dispatchSetDialog,
    dispatchResetForm,
    dispatchNotification,
    dispatchProjectInfo,
    getCurrentProject,
    FORMSTATE,
    dispatchResetFilters
  } = useRedux();
  const navigate = useNavigate();
  const dialog = getModals()?.filter(modal => modal.id === id)[0]?.content[0];

  console.log('TYPE: ', type);
  console.log('ID: ', id);
  console.log('DIALOG: ', dialog);

  const getType = type => {
    return type === 'image' ? 'dialog-image' : 'dialog';
  };

  const dialogClass = `${getType(type)}`;

  const handleClick = async action => {
    dispatchSetDialog({ open: false, type: '', id: '' });
    if (action === ACTIONS.NewDesign) {
      dispatchResetForm({ reset: true, trigger: 'RESET_STEP_PLOT' });
      FORMSTATE.reset();
      dispatchResetFilters();
      setCustomFormStep(0);
      const projectBody = getProjectBody({
        salesManager: getCurrentProject().sales_manager,
        clientEmail: getCurrentProject().client
      });
      await createProject(projectBody)
        .then(response => {
          if (response) {
            console.log('Project saved to DB', response);
            dispatchProjectInfo(response);
            navigate(`/journey/${response.id}`);
          }
        })
        .catch(error => {
          console.log('Error saving project do DB', error);
        });
    }
    if (action === ACTIONS.Exit) {
      if (id === DIALOG_TYPE.SaveExit)
        dispatchNotification({
          status: 'success',
          title: 'Your configuration was successfully saved in drafts.'
        });
      FORMSTATE.reset();
      dispatchResetFilters();
      navigate('/');
    }
    if (action === ACTIONS.Back) onClose();
    if (action === ACTIONS.ConfirmInterest) setProjectSubmittedPageOpen(true);
    return;
  };

  return (
    <div className={dialogClass.trim()}>
      {type === 'image' && <img src={dialogImg} alt='' />} {/* FIX SOURCE */}
      <div className={`${dialogClass.trim()}-content`}>
        <div className='dialog-header'>
          <div className='dialog-header-text'>
            <div className='dialog-header-text-left'>
              {id === DIALOG_TYPE.SaveProject && <span className='id'>{getCurrentProject().id}</span>}
              {/* FIX BESPOKE ID */}
              <span className='title'>{dialog.title.replace('{{projectName}}', projectName)}</span>
            </div>
            <IconButton
              className='close'
              size={'small'}
              onImageBg={false}
              icon={'semantic/close'}
              onClickFn={onClose}
            />
          </div>
          <span className='subtitle'>{dialog.description}</span>
        </div>
        {type === 'image' && <PriceCard></PriceCard>}
        <div className='dialog-footer'>
          {dialog.actions.map(action => {
            const type = action.type.toLowerCase();
            if (type === 'primary') {
              return (
                <Button
                  primary
                  text={action.label}
                  onClickFn={() => {
                    handleClick(action.action);
                  }}
                ></Button>
              );
            } else if (type === 'primary-destructive') {
              return (
                <Button
                  primary
                  destructive
                  text={action.label}
                  onClickFn={() => {
                    handleClick(action.action);
                  }}
                ></Button>
              );
            } else if (type === 'secondary') {
              return (
                <Button
                  secondary
                  text={action.label}
                  onClickFn={() => {
                    handleClick(action.action);
                  }}
                ></Button>
              );
            } else if (type === 'ghost') {
              return (
                <Button
                  ghost
                  text={action.label}
                  onClickFn={() => {
                    handleClick(action.action);
                  }}
                ></Button>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default Dialog;
