import { notificationAction } from './notification-slice';

export const setNotification = ({ dispatch, notification }) => {
  try {
    dispatch(notificationAction.setNotification(notification));
  } catch (error) {
    console.log('ERROR');
  } finally {
  }
};

export const resetNotification = dispatch => {
  try {
    dispatch(notificationAction.reset());
  } catch (error) {
    console.log('ERROR');
  } finally {
  }
};
