import { createSlice } from '@reduxjs/toolkit';

const plotsSlice = createSlice({
  name: 'plots',
  initialState: {},
  reducers: {
    setPlots: (state, action) => {
      return [...action.payload];
    }
  }
});

const plotsAction = plotsSlice.actions;
export { plotsSlice, plotsAction };
