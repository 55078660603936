const prevent = {};

export const setPrevent = fieldName => {
  prevent[fieldName] = true;
};

export const clearPrevent = fieldName => {
  prevent[fieldName] = false;
};

export const isToPrevent = fieldName => {
  return prevent[fieldName] === true;
};
