import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  stepsFinished: [],
  stepChanged: false
};

const formStateSlice = createSlice({
  name: 'formState',
  initialState: INITIAL_STATE,
  reducers: {
    updateFormState: (state, action) => {
      const { stepId, obj } = action.payload;
      state[stepId] = { ...state[stepId], ...structuredClone(obj) };
    },
    updateStepFinished: (state, action) => {
      const { stepFinished } = action.payload;
      state.stepsFinished.push(stepFinished);
    },
    updateStepsFinished: (state, action) => {
      const { stepsFinished } = action.payload;
      state.stepsFinished = stepsFinished;
    },
    updateStepChanged: (state, action) => {
      const { stepChanged } = action.payload;
      state.stepChanged = stepChanged;
    },
    reset: () => {
      return INITIAL_STATE;
    }
  }
});

const formStateActions = formStateSlice.actions;
export { formStateSlice, formStateActions };
