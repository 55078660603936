import './PriceCard.scss';
// hooks
import useRedux from '@hooks/useRedux';

const PriceCard = () => {
  const { PRICING } = useRedux();
  return (
    <div className='price-card'>
      <div className='price-card-header'>
        <span className='price-card-header-title'>Price</span>
        <span className='price-card-header-price'>
          € {PRICING.get().toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        </span>
      </div>
      <span className='price-card-description'>Price Card description.</span>
    </div>
  );
};

export default PriceCard;
