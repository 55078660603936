const Photo = ({ image, fallbackImagePath }) => {
  return (
    <img
      src={image ?? fallbackImagePath}
      alt='Left Div'
      className='full-image'
      onError={e => {
        e.target.onerror = null; // Prevent infinite loop if fallback image is missing
        e.target.src = fallbackImagePath;
      }}
    />
  );
};

export default Photo;
