import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
// hooks
import useRender from '@hooks/useRender';
import useFilters from '@hooks/useFilters';
import { useFormContext } from 'react-hook-form';
import useRedux from '@hooks/useRedux';
// components
import Integration from './Integration';
import TextOnly from './TextOnly';
import TextWithImage from './TextWithImage';
import Information from './Information';
import { SocialAreas } from './wrappers/materials/SocialAreas';
import { IntimateArea } from './wrappers/materials/IntimateArea';
import { Kitchen } from './wrappers/materials/Kitchen';
import { Pool } from './wrappers/materials/Pool';
// styling
import './shared.scss';

const RenderAnswers = ({ control, question, currentStepId, onModalOpen }) => {
  const { getTracking, getPacks } = useRedux();
  const { innerQuestionShouldRender } = useRender();
  const { getHouseLayoutsByPlotTypologyHouseType } = useFilters();
  const { watch } = useFormContext(); // Access the shared form instance via useFormContext
  const watcher = watch();

  const getController = ({ answers, answerIndex, type, shouldTriggerRenderCondition, Component }) => {
    if (answers.length > 1) {
      const innerQuestionId = answers[answerIndex].id;
      return (
        <Controller
          key={`${currentStepId}.${question.id}.${innerQuestionId}`}
          name={`${currentStepId}.${question.id}.${innerQuestionId}`}
          control={control}
          rules={{ required: true }}
          render={({ field }) =>
            answers[answerIndex].items.map(answer => (
              <Component
                //   key={question.id}
                field={field}
                item={answer}
                renderCondition={question.renderCondition || answers[answerIndex].renderCondition}
                controllerId={`${currentStepId}.${question.id}.${innerQuestionId}`}
              />
            ))
          }
        />
      );
    } else {
      return (
        <Controller
          key={`${currentStepId}.${question.id}`}
          name={`${currentStepId}.${question.id}`}
          control={control}
          rules={{ required: true }}
          render={({ field }) =>
            answers[0].items.map(answer => (
              <Component
                //   key={question.id}
                type={type}
                field={field}
                item={answer}
                shouldTriggerRenderCondition={shouldTriggerRenderCondition}
                renderCondition={question.renderCondition}
                controllerId={`${currentStepId}.${question.id}`}
              />
            ))
          }
        />
      );
    }
  };

  // useMemo: Memoized renderAnswers to prevent unnecessary re-rendering unless question, currentStepId, control, or watcher change.
  const renderAnswers = useMemo(() => {
    if (question.component === 'content-area.integration') {
      if (question.type === 'show-macroplots') {
        return question.answers.map((_, index) =>
          getController({
            answers: question.answers,
            answerIndex: index,
            Component: Integration,
            type: question.type
          })
        );
      }
      if (question.type === 'show-plots') {
        // Conditionally trigger rendering based on watcher.plot.macroplot
        const shouldTriggerRenderCondition = watcher?.plot?.macroplot !== undefined;
        return question.answers.map((_, index) =>
          getController({
            answers: question.answers,
            answerIndex: index,
            shouldTriggerRenderCondition,
            Component: Integration,
            type: question.type
          })
        );
      }
      if (question.type === 'show-houseLayout') {
        const selectedNrRooms = watcher?.layout?.nrRooms;
        const selectedHouseType = watcher?.layout?.houseType;
        const selectedPlot = watcher?.plot?.plot;

        if (selectedNrRooms && selectedHouseType) {
          const houseLayouts = getHouseLayoutsByPlotTypologyHouseType(selectedPlot, selectedNrRooms, selectedHouseType);
          return (
            <Controller
              key={`${currentStepId}.${question.id}`}
              name={`${currentStepId}.${question.id}`}
              control={control}
              rules={{ required: true }}
              render={({ field }) =>
                houseLayouts.map((answer, index) => (
                  <Integration
                    type={question.type}
                    field={field}
                    item={{
                      modules: answer.modules?.combinations || null,
                      id: answer.id ?? `Layout${index + 1}`,
                      index: index + 1
                    }}
                    renderCondition={question.renderCondition}
                    controllerId={`${currentStepId}.${question.id}`}
                  />
                ))
              }
            />
          );
        }
      }
    }

    if (question.component === 'content-area.text-only') {
      const packs = getPacks().filter(pack => pack.divisionType === 'Piscina');
      return (
        <div className='multiple-questions'>
          {question.answers.map((answerItem, answerIndex) => {
            /* if (answerItem.id === 'poolCovering') {
              const packs = getPacks().filter(pack => pack.divisionType === 'Piscina');
              return (
                <Pool
                  key={answerItem.id}
                  packs={packs}
                  question={answerItem}
                  control={control}
                  onClickFn={onModalOpen}
                />
              );
            } */
            return innerQuestionShouldRender({
              questionId: question.id,
              currentStepId,
              answerIndex
            }) ? (
              answerItem.id === 'poolCovering' ? (
                <Pool
                  key={answerItem.id}
                  packs={packs}
                  question={answerItem}
                  control={control}
                  onClickFn={onModalOpen}
                />
              ) : (
                <div className='title-answers' key={answerItem.id}>
                  {answerItem.title && <span className='title'>{answerItem.title}</span>}
                  <div className='row'>
                    {getController({
                      answers: question.answers,
                      answerIndex,
                      Component: TextOnly
                    })}
                  </div>
                </div>
              )
            ) : null;
          })}
        </div>
      );
    }

    if (['content-area.text-with-image', 'content-area.house-type'].includes(question.component)) {
      return question.answers.map(answerItem => (
        <div key={answerItem.id}>
          {answerItem.title && <span className='title'>{answerItem.title}</span>}
          <div className='houseTypeList'>
            {getController({
              answers: question.answers,
              answerIndex: 0,
              Component: TextWithImage,
              type: question.component
            })}
          </div>
        </div>
      ));
    }

    if (question.component === 'content-area.material') {
      const { interiorsAreas } = getTracking();

      if (question.type === 'social-areas') {
        const divisions = interiorsAreas[question.type];
        return <SocialAreas control={control} divisions={divisions} question={question} onClickFn={onModalOpen} />;
      }

      if (question.type === 'kitchen') {
        const { kitchens } = interiorsAreas[question.type];
        return <Kitchen control={control} kitchens={kitchens} question={question} onClickFn={onModalOpen} />;
      }

      if (question.type === 'intimate-areas') {
        const divisions = interiorsAreas[question.type];
        const { mastersuites, suites } = divisions;

        return (
          <div className='intimateContainer'>
            {mastersuites && (
              <IntimateArea suites={mastersuites} title='Master Suites' question={question} onClickFn={onModalOpen} />
            )}
            {suites && <IntimateArea suites={suites} title='Suites' question={question} onClickFn={onModalOpen} />}
          </div>
        );
      }
    }

    if (question.component === 'information.information-block') {
      return <Information key={question.id} information={question.information} onClickFn={onModalOpen} />;
    }

    return null;
  }, [question, currentStepId, control, watcher]);

  return renderAnswers;
};

export default RenderAnswers;
