import useRedux from '@hooks/useRedux';
import { isEmpty } from '@utils/functions';
import { useFormContext } from 'react-hook-form';

const useRules = () => {
  const { getMacroplots, getPlots, getHouses, getTracking } = useRedux();

  const { watch } = useFormContext(); // Access the shared form instance via useFormContext
  const watcher = watch();

  const renderPlot = ({ item }) => {
    // Get selected macroplot from watcher
    const selectedMacroplot = watcher?.plot?.macroplot;
    if (isEmpty(selectedMacroplot)) return true; // render all if no macroplot is selected
    // Get matchingPlots from selectedMacro
    const plots = getMacroplots().find(macroplot => macroplot.id === selectedMacroplot)?.plots;
    // Return true if element is on matchingPlots
    return plots?.includes(item.id);
  };

  const renderRooms = ({ item }) => {
    // Get selected plot from watcher
    const selectedPlot = watcher?.plot?.plot;
    // Get matchingHouses from selectedPlot
    const matchingHouses = getPlots().find(plot => plot.id === selectedPlot)?.houses;
    // Filter matchingHouses according selectedPlot ID
    const filteredHouses = getHouses().filter(house => matchingHouses.includes(house.id));
    // Get typology (excluding duplicates)
    const typologies = [...new Set(filteredHouses.map(house => house.typology))];
    // Return true if element is on typologies
    return typologies?.includes(item.id);
  };

  const renderHouseType = ({ item }) => {
    // Get selected plot from watcher
    const selectedPlot = watcher?.plot?.plot;
    const selectedNrRooms = watcher?.layout?.nrRooms;

    if (selectedPlot && selectedNrRooms) {
      // Get matchingHouses from selectedPlot
      const matchingHouses = getPlots().find(plot => plot.id === selectedPlot)?.houses;

      // Filter matchingHouses according selectedPlot ID
      const filteredHouses = getHouses().filter(house => matchingHouses.includes(house.id));

      // Filter filteredHouses based on selectedTypology, and Get House Types (excluding duplicates)
      const houseTypes = [
        ...new Set(filteredHouses.filter(house => house.typology === selectedNrRooms).map(house => house.houseType))
      ];

      // Return true if element is on matchingPlots
      return houseTypes?.includes(item.id);
    } else {
      // return all houseTypes as default, if no nrRooms is selected
      return true;
    }
  };

  const poolDetails = () => {
    const poolSize = watcher?.exteriors?.pool?.poolSize;
    if (poolSize === 'none') {
      return false;
    } else {
      return true;
    }
  };

  const renderMaxPoolSize = ({ item }) => {
    // Get selected plot from watcher
    const selectedPlot = watcher?.plot?.plot;
    const houseName = getTracking().houseName;
    const pool = getPlots()
      .find(plot => plot.id === selectedPlot)
      .pools.find(entry => entry.house === houseName);

    const maxPoolSize = pool.maxPoolSize;

    switch (maxPoolSize) {
      case 'XL':
        if (item.id === 'XL' || item.id === 'L' || item.id === 'none') return true;
        break;
      case 'L':
        if (item.id === 'L' || item.id === 'none') return true;
        break;
      case 'none':
        if (item.id === 'none') return true;
        break;
      default:
        console.log('No matching pool size');
    }
    return false;
  };

  const renderNrParking = ({ item }) => {
    // Get selected houseType and nrRooms from watcher
    const selectedHouseType = watcher?.layout?.houseType;
    const selectedNrRooms = watcher?.layout?.nrRooms;

    if (selectedHouseType && selectedNrRooms) {
      // Get matchingHouses from selectedHouseType and selectedNrRooms
      const matchingHouses = getHouses().filter(
        house => house.typology === selectedNrRooms && house.houseType === selectedHouseType
      );

      const nrParking = [...new Set(matchingHouses.map(house => `${house.nrParking}`))];

      // Return true if element is on nrParking
      return nrParking?.includes(item.id);
    } else {
      return true;
    }
  };

  const renderHouseTypeLayouts = () => {
    const selectedNrRooms = watcher?.layout?.nrRooms;
    const selectedHouseType = watcher?.layout?.houseType;

    if (selectedNrRooms && selectedHouseType) {
      return true;
    } else {
      return false;
    }
  };

  const RULES = {
    renderPlot: renderPlot,
    renderRooms: renderRooms,
    renderHouseType: renderHouseType,
    poolDetails: poolDetails,
    renderNrParking: renderNrParking,
    renderMaxPoolSize: renderMaxPoolSize
  };

  const RULES_QUESTIONS = {
    renderHouseTypeLayouts: renderHouseTypeLayouts
  };

  return {
    renderPlot,
    renderRooms,
    renderHouseType,
    poolDetails,
    renderNrParking,
    renderHouseTypeLayouts,
    RULES,
    RULES_QUESTIONS
  };
};

export default useRules;
