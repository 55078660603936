import { createSlice } from '@reduxjs/toolkit';

const modulesSlice = createSlice({
  name: 'modules',
  initialState: {},
  reducers: {
    setModules: (state, action) => {
      return [...action.payload];
    }
  }
});

const modulesAction = modulesSlice.actions;
export { modulesSlice, modulesAction };
