import { useEffect, useState } from 'react';
// components
import LinkButton from '@components/ui/Buttons/LinkButton/LinkButton';
import Checkbox from '@components/ui/Checkbox/Checkbox';
// styles
import './ProfileCell.scss';
import { use } from 'i18next';

const formatJobTitle = jobTitle => {
  return jobTitle
    .toLowerCase()
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const ProfileCell = ({ profile, onSelect, onDeselect, isProfileSelected }) => {
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setSelected(isProfileSelected);
  }, [isProfileSelected]);

  return (
    <div className={selected ? 'profile-cell selected' : 'profile-cell'}>
      <div className='profile-cell-header'>
        <img src={profile.image} alt='' />
        <div className='profile-cell-content'>
          <div className='profile-cell-text'>
            <span className='profile-cell-text-name'>{profile.name}</span>
            <span className='profile-cell-text-jobTitle'>{formatJobTitle(profile.jobTitle)}</span>
          </div>
          <LinkButton
            size='extra-small'
            text='Know more'
            onClickFn={() => {
              /* navigate(profile.url) */
            }}
          ></LinkButton>
        </div>
      </div>
      <div className='profile-cell-right'>
        <Checkbox
          onSelect={() => {
            setSelected(!selected);
            onSelect();
          }}
          onDeselect={() => {
            setSelected(!selected);
            onDeselect();
          }}
          value={selected}
        ></Checkbox>
      </div>
    </div>
  );
};

export default ProfileCell;
