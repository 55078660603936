import './LinkButton.scss';
import { SVG } from '@assets/svg';

const LinkButton = ({ size, icon, text, onClickFn }) => {
  const LinkButtonClass = `
    ${size === 'extra-small' ? 'link-button_extra-small' : 'link-button_default'} 
  `;
  return (
    <div className='link-button' onClick={onClickFn}>
      {icon && <SVG icon={icon} />}
      <span className={`${LinkButtonClass.trim()} text`}>{text}</span>
    </div>
  );
};

export default LinkButton;
