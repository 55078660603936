import { createSlice } from '@reduxjs/toolkit';

const clientSlice = createSlice({
  name: 'client',
  initialState: {},
  reducers: {
    setClient: (state, action) => {
      return { ...action.payload };
    }
  }
});

const clientActions = clientSlice.actions;
export { clientSlice, clientActions };
