import { createSlice } from '@reduxjs/toolkit';

const housesSlice = createSlice({
  name: 'houses',
  initialState: {},
  reducers: {
    setHouses: (state, action) => {
      return [...action.payload];
    }
  }
});

const housesAction = housesSlice.actions;
export { housesSlice, housesAction };
