import { openDB } from 'idb';

const dbPromise = openDB('video-storage', 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains('videos')) {
      db.createObjectStore('videos');
    }
  }
});

async function saveVideoToCache(videoId, videoBlob) {
  const db = await dbPromise;
  await db.put('videos', videoBlob, videoId);
}

async function getVideoFromCache(videoId) {
  const db = await dbPromise;
  return await db.get('videos', videoId);
}

export async function fetchVideo(videoId, url) {
  const cachedVideo = await getVideoFromCache(videoId);

  if (cachedVideo) {
    console.log('video cached', cachedVideo);
    return URL.createObjectURL(cachedVideo);
  }

  const response = await fetch(url);
  const videoBlob = await response.blob();
  saveVideoToCache(videoId, videoBlob);
  
  if (videoBlob instanceof Blob) {
    const blobUrl = URL.createObjectURL(videoBlob);
    return blobUrl;
  } else {
    console.error('O conteúdo recuperado não é um Blob válido:', videoBlob);
    throw new Error('Conteúdo inválido no cache.');
  }
}
