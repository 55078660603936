import { DetailedCell as DetailedCell_Root } from './DetailedCell';
import DetailedCell_Text from './DetailedCell_Text';
import DetailedCell_Title from './DetailedCell_Title';
import DetailedCell_Row from './DetailedCell_Row';
import DetailedCell_Description from './DetailedCell_Description';
import DetailedCell_Image from './DetailedCell_Image';

const DetailedCell = {
  Root: DetailedCell_Root,
  Text: DetailedCell_Text,
  Title: DetailedCell_Title,
  Row: DetailedCell_Row,
  Description: DetailedCell_Description,
  Image: DetailedCell_Image
};

export default DetailedCell;
