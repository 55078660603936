import { authAction } from './auth-slice';

export const setAuthInfo = async (dispatch, authInfo) => {
  try {
    dispatch(authAction.setAuth([authInfo]));
  } catch (error) {
    console.log('ERROR setting auth on redux', error);
  } finally {
  }
};
