import { useState } from 'react';
// styles
import './TextInput.scss';
// utils
import { SVG } from '@assets/svg';

const TextInput = ({
  label,
  name,
  icon,
  lock,
  placeholder,
  value,
  onChangeFn,
  clearFn,
  required,
  errorMessage,
  customCSS,
  onEnterPress
}) => {
  const [isInputFocused, setIsInputFocused] = useState(false);

  const handleKeyDown = event => {
    if (event.key === 'Enter' && onEnterPress) {
      onEnterPress(event);
    }
  };

  return (
    <div className='textInput'>
      <span className='label'>{label}</span>
      <div className='input-wrapper'>
        <SVG icon={icon} customCSS={`left-icon${lock ? ' lock' : ''}`} />
        <input
          type='text'
          className={`input${errorMessage ? ' error' : ''}`}
          name={name}
          placeholder={placeholder}
          value={value}
          {...(onChangeFn && { onChange: onChangeFn })}
          onFocus={() => setIsInputFocused(true)}
          onBlur={() => setIsInputFocused(false)}
          {...(required && { required: true })}
          style={customCSS}
          onKeyDown={handleKeyDown}
        />
        {isInputFocused && !lock && value && (
          <button type='button' className='clear-icon' onMouseDown={clearFn}>
            <SVG icon='semantic/circle-x' />
          </button>
        )}
        {lock && (
          <button type='button' className='lock-icon'>
            <SVG icon='action/lock-outline' />
          </button>
        )}
      </div>
      <span className='errorMessage'>{errorMessage}</span>
    </div>
  );
};

export default TextInput;
