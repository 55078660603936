import axios from 'axios';
import { getFullEndpoint } from '../utils/endpoints/functions';

export const getModals = async () => {
  const HEADERS = {
    accept: '*/*'
    // Authorization: `Bearer 351ea975a1935bf5ef366c0bf43065fd32acd7fe2cabf785f26792d37739ca9e944cc3d50a2cc663a4a801f9ad9cadf4f10c1f196107a37536906c27872dda2d9a92746929ad22801a68d9fcb8de5921580da70532cf5fe60371b8e782021cc7891d7b5f0854b657513d0c9ca6e3666ec42e3f6fe89b187d7b851e7121206203`,
  };
  const requestObj = {
    method: 'get',
    url: getFullEndpoint({ microservice: 'modals' }),
    headers: HEADERS
  };

  const response = await axios.request(requestObj).catch(e => console.log(e));
  // const response = await axios.request(requestObj).catch(error => handleError(error));
  return response.data;
};
