import { isEmpty } from './functions';
import { flattenObject, isArray } from './utils';

const IMAGE_STEP_MAP = {
  plot: 0,
  houseLayout: 1,
  exteriors: 2,
  interiors: 3,
  review: 4
};

// const getPath = (path) => {
//   if (isArray(path)) {
//     return `img/${path.join("-")}.png`;
//   } else {
//     return `img/${path}.png`;
//   }
// };

// export const updateImage = (watcher, currentStep) => {
//   const fields = flattenObject(watcher);
//   const baseFileName = "macroPlotA";

//   // image path to be renderer
//   let plotStepPath = [];
//   let remainingPath = "";

//   //   let remainingPath = {
//   //     previous: '',
//   //     current: '',
//   //   };

//   const paths = {
//     plot: [],
//     houseLayout: "",
//     exteriors: "",
//     current: "",
//     review: "",
//   };

//   // Add field values to path in the correct order
//   //   if (watcher.plot.macroplot) plotStepPath.push(watcher.plot.macroplot);
//   //   if (watcher.plot.plot) plotStepPath.push(watcher.plot.plot);

//   //   if (watcher.plot.macroplot) paths.plot.push(watcher.plot.macroplot);
//   //   if (watcher.plot.plot) paths.plot.push(watcher.plot.plot);

//   if (currentStep === IMAGE_STEP_MAP["review"]) {
//     return "img/review.png";
//   }

//   if (watcher.plot) {
//     paths.current = "plot";
//     if (watcher.plot.macroplot) paths.plot.push(watcher.plot.macroplot);
//     if (watcher.plot.plot) paths.plot.push(watcher.plot.plot);
//   }

//   //   if (watcher.houseLayout.houseType) {
//   //     remainingPath = watcher.houseLayout.houseType;
//   //     if (currentStep < IMAGE_STEP_MAP['houseLayout']) {
//   //       return `img/${plotStepPath.join('-')}.png`;
//   //     }
//   //   }

//   if (watcher.houseLayout.houseType) {
//     paths.current = "houseLayout";
//     paths.houseLayout = watcher.houseLayout.houseType;

//     if (currentStep < IMAGE_STEP_MAP["houseLayout"]) {
//       return getPath(paths.plot);
//     }
//   }

//   //   if (watcher.exteriors) {
//   //     if (watcher.exteriors.claddingDeck.material) {
//   //       remainingPath = watcher.exteriors.claddingDeck.material;
//   //       if (currentStep < IMAGE_STEP_MAP['exteriors']) {
//   //         return `img/${plotStepPath.join('-')}.png`;
//   //       }
//   //     }
//   //   }

//   if (watcher.exteriors) {
//     paths.current = "exteriors";
//     if (currentStep < IMAGE_STEP_MAP["exteriors"]) {
//       return getPath(paths.houseLayout);
//     }
//     if (watcher.exteriors.claddingDeck.material) {
//       paths.exteriors = watcher.exteriors.claddingDeck.material;
//     }

//     if (watcher.exteriors?.swimmingPool?.size) {
//       paths.exteriors = watcher.exteriors.swimmingPool.size;
//     }

//     if (watcher.exteriors?.parkingArea?.type) {
//       paths.exteriors = [];
//       paths.exteriors.push(watcher.exteriors?.parkingArea?.type);
//       if (watcher.exteriors?.parkingArea?.parkingSpace) {
//         paths.exteriors.push(watcher.exteriors?.parkingArea?.parkingSpace);
//       }
//     }
//   }

//   if (watcher.interiors) {
//     paths.current = "interiors";
//     if (currentStep < IMAGE_STEP_MAP["interiors"]) {
//       return getPath(paths.exteriors);
//     }
//     if (watcher.interiors.interiorMood) {
//       paths.interiors = watcher.interiors.interiorMood;
//     }
//   }

//   if (!isEmpty(paths.current)) {
//     return getPath(paths[paths.current]);
//   }

//   //   if (!isEmpty(paths.plot)) {
//   //     let newImageUrl;
//   //     if (paths.plot.length === 0) {
//   //       newImageUrl = `img/${baseFileName}.png`;
//   //     } else {
//   //       newImageUrl = `img/${paths.plot.join('-')}.png`;
//   //     }
//   //     return newImageUrl;
//   //   }

//   //   let newImageUrl;
//   //   if (plotStepPath.length === 0) {
//   //     newImageUrl = `img/${baseFileName}.png`;
//   //   } else {
//   //     newImageUrl = `img/${plotStepPath.join('-')}.png`;
//   //   }

//   //   return newImageUrl;
// };

const isOneLevelDeep = obj => {
  // Check if it's a valid object and not null
  if (typeof obj !== 'object' || obj === null) return false;

  return Object.values(obj).every(value => typeof value !== 'object' || value === null);
};

export const getUrl = watcher => {
  const selections = isOneLevelDeep(watcher) ? watcher : flattenObject(watcher);
  console.log(selections);
  let final = '';

  for (let [key, value] of Object.entries(selections)) {
    if (value) {
      if (final.length === 0) final = `?${final}${key}=${value}`;
      else final = `${final}&${key}=${value}`;
    }
  }
  return final;
};
