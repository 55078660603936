import '../../shared.scss';
import { MaterialController } from '@components/answers/controllers/MaterialController';
import SectionMaterial from '@components/answers/SectionMaterial';
import useRedux from '@hooks/useRedux';

export const Kitchen = ({ control, kitchens, question, onClickFn }) => {
  const { getCurrentStepId } = useRedux();
  return (
    <div className='materialContainer'>
      {kitchens.map((kitchen, index) => (
        <>
          <div key={index} className='materialWrapper'>
            <span className='title'>{kitchen.kitchen.title}</span>
            <MaterialController
              control={control}
              currentStepId={getCurrentStepId()}
              division={{ ...kitchen.kitchen, id: 'kitchen' }}
              question={question}
              onClickFn={onClickFn}
              Component={SectionMaterial}
            />
          </div>
          <div key={index} className='materialWrapper'>
            <span className='title'>{kitchen.appliances.title}</span>
            <MaterialController
              control={control}
              currentStepId={getCurrentStepId()}
              division={{ ...kitchen.appliances, id: 'appliance' }}
              question={question}
              Component={SectionMaterial}
            />
          </div>
        </>
      ))}
    </div>
  );
};
