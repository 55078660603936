import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import axios from 'axios';

// hooks
import useRedux from '@hooks/useRedux';
// utils
import { isEmpty } from '@utils/functions';
import { getFullEndpoint } from '@utils/endpoints/functions';
// pages
import Homepage from '@pages/Homepage/Homepage';
import ProtectedRoute from '@components/auth/ProtectedRoute';
import SalesMangerLogin from '@pages/SalesManager/login/login';
import ClientIdPage from '@pages/Client/ClientIdPage';
import InfoPageComponent from '@pages/InfoPages/InfoPageComponent';
import VerifyCodePage from '@pages/OTP/VerifyCode';
import ClientRegistration from '@pages/ClientRegistration/ClientRegistration';
import ClientOTPRequest from '@pages/OTP/ClientOTPRequest';
// components
import JourneyBuilder from '@components/forms/JourneyBuilder';
import Notification from '@components/ui/Notification/Notification';
import Dashboard from '@pages/Dashboard/Dashboard';
//services
import { GetInfoPagesContent } from '@services/infoPages';

function App() {
  // if (typeof process === 'undefined') {
  //   global.process = {
  //     nextTick: callback => setTimeout(callback, 0),
  //   };
  // }
  const {
    dispatchForms,
    dispatchMacroplots,
    dispatchPlots,
    dispatchHouses,
    dispatchModules,
    dispatchHouseTypes,
    dispatchPacks,
    dispatchModals,
    dispatchPartners,
    dispatchHomepageContent,
    dispatchProjectSubmittedContent,
    getForms,
    getMacroplots,
    getPlots,
    getNotification,
    getHomepageContent,
    dispatchSVG
  } = useRedux();

  const [infoPagesContent, setInfoPagesContent] = useState(null);

  const forms = getForms();
  const macroplots = getMacroplots();
  const plots = getPlots();
  const homepageContent = getHomepageContent();
  const notification = getNotification();

  useEffect(() => {
    dispatchMacroplots();
    dispatchPlots();
    dispatchHouses();
    dispatchModules();
    dispatchHouseTypes();
    dispatchPacks();
    dispatchModals();
    dispatchPartners();
    dispatchHomepageContent();
    dispatchProjectSubmittedContent();
    dispatchSVG();
  }, []);

  useEffect(() => {
    if (!isEmpty(macroplots) && !isEmpty(plots) && isEmpty(forms)) {
      dispatchForms({ additionalData: { macroplots, plots } });
    }
  }, [macroplots, plots]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const infoPages = await GetInfoPagesContent();
        if (!isEmpty(infoPages)) {
          setInfoPagesContent(infoPages);
        }
      } catch (error) {
        console.error('Error fetching info pages:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Homepage homepageContent={homepageContent} />} />
          <Route path='/sales-manager/login' element={<SalesMangerLogin />} />
          <Route path='/journey/:id' element={<ProtectedRoute element={<JourneyBuilder />} />} />
          <Route path='/client' element={<ProtectedRoute element={<ClientIdPage />} />} />
          <Route path='/dashboard' element={<ProtectedRoute element={<Dashboard/>} />} />
          <Route path='/client/register' element={<ProtectedRoute element={<ClientRegistration />} />} />
          <Route path='/client/verify' element={<ProtectedRoute element={<VerifyCodePage />} />} />
          {infoPagesContent?.termsData && (
            <Route
              path='/terms-and-conditions'
              element={<ProtectedRoute element={<InfoPageComponent data={infoPagesContent.termsData} />} />}
            />
          )}
          {infoPagesContent?.privacyData && (
            <Route
              path='/privacy-policy'
              element={<ProtectedRoute element={<InfoPageComponent data={infoPagesContent.privacyData} />} />}
            />
          )}
          <Route path='/client/register/request' element={<ClientOTPRequest />} />
        </Routes>
      </Router>
      {notification.visibility && (
        <Notification
          timer={notification.timer}
          type={notification.type}
          length={notification.length}
          status={notification.status}
          action={notification.action}
          title={notification.title}
          description={notification.description}
        />
      )}
    </>

    // <div className='App'>
    //   {!isEmpty(forms.journey) && <JourneyBuilder svg={svg} />}
    //   {/* <LeadIntegration /> */}
    // </div>
  );
}

export default App;
