import { Snackbar } from '@mui/base/Snackbar';
import { SVG } from '@assets/svg';
import './Notification.scss';
// hooks
import useRedux from '@hooks/useRedux';
import LinkButton from '../Buttons/LinkButton/LinkButton';

export default function Notification({ timer, type, status, action, title, description }) {
  const { dispatchResetNotification } = useRedux();

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') return;
    dispatchResetNotification();
  };

  const getType = type => {
    if (type === 'toast') return '-toast';
    else return '';
  };

  const getStatus = status => {
    if (status === 'error') return '-error';
    else if (status === 'informational') return '-informational';
    else return '';
  };

  const notificationClass = `
    notification${getType(type)?.trim()}${getStatus(status)?.trim()} 
  `;

  return (
    <Snackbar
      open={true}
      autoHideDuration={timer} // 5 seconds
      onClose={handleClose}
      className={notificationClass.trim()}
    >
      <div className='message'>
        <div className='left'>
          {status === 'success' && <SVG className='icon' icon='semantic/check-circle' />}
          {status === 'informational' && <SVG className='icon' icon='semantic/info-filled' />}
          {status === 'error' && <SVG className='icon' icon='semantic/octagon-x-filled' />}
          <div className='notification-content'>
            <span className='message-text'>{title}</span>
            {description && (
              <span className={`message-description${getStatus(status)?.trim()}`.trim()}>{description}</span>
            )}
          </div>
        </div>
        <div className='right' onClick={handleClose}>
          {!description && action && <LinkButton text={action} onClickFn={() => {}} />}
          <SVG icon='semantic/close' />
        </div>
      </div>
    </Snackbar>
  );
}
