import '../../shared.scss';
import { MaterialController } from '@components/answers/controllers/MaterialController';
import SectionMaterial from '@components/answers/SectionMaterial';
import useRedux from '@hooks/useRedux';

export const IntimateArea = ({ control, suites, question, onClickFn }) => {
  const { getCurrentStepId } = useRedux();
  return suites.map((suite, index) => (
    <div key={index} className='materialContainer'>
      <div className='materialWrapper'>
        <span className='title'>{suite.suite.title}</span>
        <MaterialController
          control={control}
          currentStepId={getCurrentStepId()}
          division={suite.suite}
          question={question}
          onClickFn={onClickFn}
          Component={SectionMaterial}
        />
      </div>
      <div className='materialWrapper'>
        <span className='title'>{suite.bathroom.title}</span>
        <MaterialController
          control={control}
          currentStepId={getCurrentStepId()}
          division={suite.bathroom}
          question={question}
          onClickFn={onClickFn}
          Component={SectionMaterial}
        />
      </div>
    </div>
  ));
};
