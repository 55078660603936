import './DetailedCell.scss';

const DetailedCell_Description = ({ medium, regular, title, children }) => {
  if (title) return <span className='detailed-cell_description title'>{children}</span>;
  else if (medium) return <span className='detailed-cell_description medium'>{children}</span>;
  else if (regular) return <span className='detailed-cell_description regular'>{children}</span>;
  else return <span className='detailed-cell_description medium'>{children}</span>;
};

export default DetailedCell_Description;
