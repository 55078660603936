// src/Portal.js
import React from 'react';
import ReactDOM from 'react-dom';
import './Portal.scss';

const Portal = ({ children }) => {
  return ReactDOM.createPortal(
    <div className='portal-overlay'>
      <div className='portal-content'>{children}</div>
    </div>,
    document.body
  );
};

export default Portal;
