import { useState, useRef, useEffect } from 'react';
// components
import { Popper } from '@mui/base/Popper';
import { SVG } from '@assets/svg';
// hooks
import useRender from '@hooks/useRender';
// styles
import './Material.scss';

const Material = ({ imgUrl, field, id, controllerId, item }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const visible = Boolean(anchorEl);
  const targetRef = useRef(null);
  const popperRef = useRef(null);
  const popperId = visible ? 'simple-popper' : undefined;

  const isSelected = useRender().isElementSelected({ controllerId, item: { ...item, id: id } });

  const handleMouseEnter = () => {
    setAnchorEl(targetRef.current);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
        className={`material${isSelected ? ' selected' : ''}`}
        ref={targetRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ backgroundImage: imgUrl ? `url(${imgUrl})` : 'none' }}
        onClick={() => {
          field.onChange(id);
        }}
      >
        {isSelected && <SVG icon='semantic/check-circle-filled' customCSS='check-icon' />}
      </div>
      <Popper id={popperId} open={visible} anchorEl={anchorEl} placement='top-start' style={{ zIndex: 9999 }}>
        <div className='material-tooltip' ref={popperRef}>
          <span className='material-name'>{item.label}</span>
        </div>
      </Popper>
    </>
  );
};

export default Material;
