import { useState, useEffect, useRef } from 'react';
// components
import IconButton from '@components/ui/Buttons/IconButton/IconButton';
import Information from '@components/ui/Information';
import Dropdown from '@components/ui/Dropdown/Dropdown';
import Button from '@components/ui/Buttons/Button/Button';
import Checkbox from '@components/ui/Checkbox/Checkbox';
import RangeSlider from '@components/ui/Slider/RangeSlider';
import RadioButton from '@components/ui/RadioButton/RadioButton';
import Portal from '@components/ui/Overlays/Portal/Portal';
import BasicModal from '@components/ui/Overlays/Modals/BasicModal/BasicModal';
// hooks
import useRedux from '@hooks/useRedux';
import useFilters from '@hooks/useFilters';
// styling
import './FiltersSorting.scss';
// utils
import { isEmpty } from '@utils/functions';

const convertAreaToNumber = area => {
  if (area !== 'Min' && area !== 'Max') {
    const areaNumber = parseFloat(area.replace(/[^0-9]/g, ''));
    return areaNumber;
  }
};

const convertNumberToArea = number => {
  if (typeof number === 'number') {
    const numberString = number.toString();
    const area = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return `${area}m²`;
  } else {
    return number;
  }
};

const FiltersSorting = ({ onClose, onView, onClickFn }) => {
  const plotAreaOptions = ['Min', 2000, 4000, 6000, 8000, 10000, 'Max'];
  const sortLabels = ['Name', 'Size (Ascending Order)', 'Size (Descending Order)'];
  const { getHouseTypes, getForms, dispatchFilters, getFilters, getHouses, getModals } = useRedux();
  const { getPlotsFiltered, getPlotsByPlotArea } = useFilters();
  const houseTypes = getHouseTypes();
  const nrRooms = getForms().journey[1].questions[0].answers[0].items;
  const plots = getForms().journey[0].questions[1].answers[0].items;
  const houses = getHouses();
  let plotsFiltered = plots;
  let plotsByArea = plots;
  let nrRoomsFiltered = nrRooms.filter(room => plots.some(plot => plot.houses.some(house => house.includes(room.id))));
  let houseTypesFiltered = houseTypes.filter(houseType =>
    plots.some(plot => plot.houses.some(house => house.includes(houseType.id)))
  );
  let plotMinAreaOptionsFiltered = plotAreaOptions;
  let plotMaxAreaOptionsFiltered = plotAreaOptions;

  const [filterState, setFilterState] = useState(
    !isEmpty(getFilters()) ? getFilters() : { sortBy: 'Name', plotMinArea: 'Min', plotMaxArea: 'Max' }
  );

  const [isPortalOpen, setIsPortalOpen] = useState(false);
  const modalRef = useRef(null);
  const togglePortal = () => {
    setIsPortalOpen(!isPortalOpen);
  };
  // Close modal on 'esc' key press
  useEffect(() => {
    const handleEscKey = event => {
      if (event.key === 'Escape') {
        togglePortal();
      }
    };
    if (isPortalOpen) window.addEventListener('keydown', handleEscKey);
    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [isPortalOpen, togglePortal]);

  // Close modal on click outside the modal
  useEffect(() => {
    const handleClickOutside = event => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        togglePortal();
      }
    };
    if (isPortalOpen) window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isPortalOpen, togglePortal]);

  // filter bedrooms options by area && filter houseType options by area
  if (filterState?.plotMinArea !== 'Min' || filterState?.plotMaxArea !== 'Max') {
    plotsByArea = getPlotsByPlotArea(filterState, plots);
    nrRoomsFiltered = nrRoomsFiltered.filter(room =>
      plotsByArea.some(plot => plot.houses.some(house => house.includes(room.id)))
    );
    houseTypesFiltered = houseTypesFiltered.filter(houseType =>
      plotsByArea.some(plot => plot.houses.some(house => house.includes(houseType.id)))
    );
  }

  // filter bedrooms options by houseType
  const housesFilteredByHouseType = houses.filter(house =>
    filterState?.houseType?.some(houseType => house.houseType === houseType)
  );
  if (filterState?.houseType?.length > 0) {
    const housesFilteredByPlots = housesFilteredByHouseType.filter(house =>
      plotsByArea.some(plot => plot.houses.includes(house.id))
    );
    nrRoomsFiltered = nrRoomsFiltered.filter(room => housesFilteredByPlots.some(house => house.typology === room.id));
  }

  // filter houseType options by bedrooms
  const housesFilteredByTypology = houses.filter(house =>
    filterState?.bedrooms?.some(bedroom => house.typology === bedroom)
  );
  if (filterState?.bedrooms?.length > 0) {
    const housesFilteredByPlots = housesFilteredByTypology.filter(house =>
      plotsByArea.some(plot => plot.houses.includes(house.id))
    );
    houseTypesFiltered = houseTypesFiltered.filter(houseType =>
      housesFilteredByPlots.some(house => house.houseType === houseType.id)
    );
  }

  // filter area options by bedrooms && // filter area options by houseType
  if (filterState?.bedrooms?.length > 0 && filterState?.houseType?.length === 0) {
    plotsFiltered = plotsFiltered.filter(plot =>
      housesFilteredByTypology.some(house => plot.houses.includes(house.id))
    );
  } else if (filterState?.houseType?.length > 0 && filterState?.bedrooms?.length === 0) {
    plotsFiltered = plotsFiltered.filter(plot =>
      housesFilteredByHouseType.some(house => plot.houses.includes(house.id))
    );
  } else if (filterState?.bedrooms?.length > 0 && filterState?.houseType?.length > 0) {
    const housesFilteredByTypologyAndHouseType = housesFilteredByTypology.filter(house =>
      filterState?.houseType?.some(houseType => house.houseType === houseType)
    );
    plotsFiltered = plotsFiltered.filter(plot =>
      housesFilteredByTypologyAndHouseType.some(house => plot.houses.includes(house.id))
    );
  }
  plotMinAreaOptionsFiltered = plotMinAreaOptionsFiltered.filter(area =>
    plotsFiltered.some(plot => area <= plot.area || area === 'Min')
  );
  plotMaxAreaOptionsFiltered = plotMaxAreaOptionsFiltered.filter(area =>
    plotsFiltered.some(plot => area >= plot.area || area === 'Max')
  );

  const numberLabel = () => {
    console.log('Filter State: ', filterState);
    const plotCounter = getPlotsFiltered(filterState, plots).length;
    if (plotCounter === 0) {
      return '';
    } else {
      return ` ${plotCounter}`;
    }
  };

  return (
    <>
      <div className='filter-header'>
        <IconButton size={'small'} onImageBg={false} icon={'semantic/close'} onClickFn={onClose} />
      </div>
      <div className='filter-content'>
        <div className='filter-title'>
          <span className='headline'>Filters</span>
          <span className='description'>
            To get the most personalised experience, you may sort and filter your options here.
          </span>
        </div>
        <div className='question-title'>
          <span>Plot area</span>
          <div className='dropdown'>
            <Dropdown
              label='Minimum Size'
              options={plotMinAreaOptionsFiltered.map(option => convertNumberToArea(option))}
              onChange={item => {
                if (filterState?.plotMaxArea && convertAreaToNumber(item) > filterState.plotMaxArea) {
                  const { plotMaxArea, ...newFilterState } = filterState;
                  setFilterState({ ...newFilterState, plotMinArea: convertAreaToNumber(item) || 'Min' });
                } else {
                  setFilterState({ ...filterState, plotMinArea: convertAreaToNumber(item) || 'Min' });
                }
              }}
              value={filterState?.plotMinArea ? convertNumberToArea(filterState.plotMinArea) : 'Min'}
            ></Dropdown>
            <Dropdown
              label='Maximum Size'
              options={plotMaxAreaOptionsFiltered.map(option => convertNumberToArea(option))}
              onChange={item => {
                if (filterState?.plotMinArea && convertAreaToNumber(item) < filterState.plotMinArea) {
                  const { plotMinArea, ...newFilterState } = filterState;
                  setFilterState({ ...newFilterState, plotMaxArea: convertAreaToNumber(item) || 'Max' });
                } else {
                  setFilterState({ ...filterState, plotMaxArea: convertAreaToNumber(item) || 'Max' });
                }
              }}
              value={filterState?.plotMaxArea ? convertNumberToArea(filterState.plotMaxArea) : 'Max'}
            ></Dropdown>
          </div>
        </div>

        <div className='question-title'>
          <span>Bedrooms</span>
          {nrRooms.map(room => (
            <Checkbox
              label={room.label}
              disabled={nrRoomsFiltered !== undefined && !nrRoomsFiltered?.includes(room)}
              onSelect={() =>
                setFilterState(prevState => ({ ...prevState, bedrooms: [...(prevState?.bedrooms || []), room.id] }))
              }
              onDeselect={() => {
                setFilterState(prevState => {
                  return {
                    ...prevState,
                    bedrooms: prevState.bedrooms.filter(bedroom => bedroom !== room.id)
                  };
                });
              }}
              value={filterState?.bedrooms?.includes(room.id) || false}
            />
          ))}
        </div>
        <div className='question-information'>
          <Information.Root onClickFn={togglePortal}>
            <Information.Title>Know more about our House Types</Information.Title>
            <Information.Description>
              We recommend layouts based on the selected plot. Find out how a finalized house could look like.
            </Information.Description>
          </Information.Root>
          <div className='house-type'>
            <span>House type</span>
            {houseTypes.map(house => {
              return (
                <Checkbox
                  label={house.label}
                  disabled={houseTypesFiltered !== undefined && !houseTypesFiltered.includes(house)}
                  onSelect={() =>
                    setFilterState(prevState => ({
                      ...prevState,
                      houseType: [...(prevState?.houseType || []), house.id]
                    }))
                  }
                  onDeselect={() =>
                    setFilterState(prevState => {
                      return {
                        ...prevState,
                        houseType: prevState.houseType.filter(type => type !== house.id)
                      };
                    })
                  }
                  value={filterState?.houseType?.includes(house.id) || false}
                />
              );
            })}
          </div>
        </div>
        {isPortalOpen && (
          <Portal>
            <div ref={modalRef}>
              <BasicModal
                currentStepNr={1}
                modals={getModals().filter(modal => modal.id === 'house-types')[0].content}
                onClose={togglePortal}
                ref={modalRef}
              ></BasicModal>
            </div>
          </Portal>
        )}
        <div className='question-title'>
          <span>Price Range</span>
          <RangeSlider
            min={3000000}
            max={12500000}
            step={500000}
            onSelect={([min, max]) => setFilterState({ ...filterState, priceMin: min, priceMax: max })}
            value={filterState?.priceMin ? [filterState.priceMin, filterState.priceMax] : [3000000, 12500000]}
          />
        </div>
      </div>
      <div className='sorting-content'>
        <div className='sorting-title'>
          <span className='headline'>Sorting</span>
        </div>
        <div className='question-title'>
          <span>Sort by</span>
          {sortLabels.map(sort => {
            return (
              <RadioButton
                name='sorting'
                id={sort}
                label={sort}
                onSelect={() => setFilterState({ ...filterState, sortBy: sort })}
                value={filterState?.sortBy == sort ? filterState?.sortBy : false}
              />
            );
          })}
        </div>
      </div>
      <div className='filter-footer'>
        <Button
          secondary
          text={'Clear all'}
          onClickFn={() => {
            setFilterState({ sortBy: 'Name', plotMinArea: 'Min', plotMaxArea: 'Max' });
            dispatchFilters({ filters: {} });
          }}
        />
        <Button
          primary
          text={'View'}
          number={numberLabel()}
          onClickFn={() => {
            dispatchFilters({ filters: filterState });
            onView();
          }}
        />
      </div>
    </>
  );
};

export default FiltersSorting;
