import { createSlice } from '@reduxjs/toolkit';

const formsSlice = createSlice({
  name: 'forms',
  initialState: {},
  reducers: {
    setForm: (state, action) => {
      const { id, steps } = action.payload;
      return { ...state, [id]: steps };
    }
  }
});

const formsActions = formsSlice.actions;
export { formsSlice, formsActions };
