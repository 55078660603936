import { SVG } from '@assets/svg';
import './MenuItem.scss';
import { useState } from 'react';

const MenuItem = ({ destructive, text, icon, selected, onClickFn, ...props }) => {
  const menuItemClass = `menu-item${destructive ? ' destructive' : ''}${selected ? ' selected' : ''}${icon ? ' icon' : ''}`;
  const [selection, setSelection] = useState(selected);
  console.log('className', menuItemClass);

  return (
    <button
      class={menuItemClass}
      onClick={() => {
        setSelection(!selection);
        onClickFn();
      }}
    >
      {text}
      {icon && selection && <SVG icon={icon} />}
    </button>
  );
};

export default MenuItem;
