import { projectAction } from './project-slice';

export const setCurrentProject = async (dispatch, projectInfo) => {
  try {
    dispatch(projectAction.setCurrentProject(projectInfo));
  } catch (error) {
    console.log('ERROR', error);
  } finally {
  }
};
