// hooks
import useRender from '@hooks/useRender';
import useRedux from '@hooks/useRedux';
// components
import DetailedCell from '@components/ui/Cells/DetailedCell';
import State from '@components/ui/State/State';
// utils
import { PLOT_STATUS } from '@utils/enums';

const Integration = ({ type, field, item, renderCondition, shouldTriggerRenderCondition, controllerId }) => {
  const isSelected = useRender().isElementSelected({ controllerId, item });
  const { dispatchInteriorsAreas, dispatchSetRecommendation } = useRedux();

  const getDetails = item => {
    const finale = {
      intimate: [],
      intimate_others: 0,
      social: [],
      social_others: 0,
      area: 0
    };

    item.modules?.forEach(module => {
      module.divisions?.forEach(division => {
        finale.area = finale.area + division.area;
        if (division.category === 'Area Social' && division.type !== 'Outros') finale.social.push(division);
        if (division.category === 'Area Social' && division.type === 'Outros')
          finale.social_others = finale.social_others + division.area;
        if (division.category === 'Area Intima' && division.type !== 'Outros') finale.intimate.push(division);
        if (division.category === 'Area Intima' && division.type === 'Outros')
          finale.intimate_others = finale.intimate_others + division.area;
      });
    });
    if (finale.social_others > 0)
      finale.social.push({ area: parseFloat(finale.social_others).toFixed(2), label: 'Others' });
    if (finale.intimate_others > 0)
      finale.intimate.push({ area: parseFloat(finale.intimate_others).toFixed(2), label: 'Others' });

    return finale;
  };

  const LayoutDetails = ({ title, data }) => {
    return (
      <div className='layout-area'>
        <DetailedCell.Row>
          <DetailedCell.Description title>{title}</DetailedCell.Description>
        </DetailedCell.Row>

        <div className='idented-area'>
          {data.map(item => (
            <DetailedCell.Row key={item.label}>
              <DetailedCell.Description medium>{item.label}</DetailedCell.Description>
              <DetailedCell.Description regular>{parseFloat(item.area).toFixed(2)}m²</DetailedCell.Description>
            </DetailedCell.Row>
          ))}
        </div>
      </div>
    );
  };

  const getById = {
    'show-macroplots': () => {
      return (
        <DetailedCell.Root field={field} item={item} renderCondition={renderCondition} isSelected={isSelected}>
          <DetailedCell.Title>{item.label}</DetailedCell.Title>
        </DetailedCell.Root>
      );
    },
    'show-plots': () => {
      const { status } = item;
      if (status !== PLOT_STATUS.Sold) {
        return (
          <DetailedCell.Root
            field={field}
            item={item}
            renderCondition={renderCondition}
            shouldTriggerRenderCondition={shouldTriggerRenderCondition}
            isSelected={isSelected}
            customFn={() => dispatchSetRecommendation(item.recommendation)}
          >
            {status === PLOT_STATUS.InNegotiation ? (
              <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <DetailedCell.Title>{item.label}</DetailedCell.Title>
                <State type='warning' text='IN NEGOTIATION' />
              </div>
            ) : (
              <DetailedCell.Title>{item.label}</DetailedCell.Title>
            )}
            <DetailedCell.Row>
              <DetailedCell.Description medium>Area</DetailedCell.Description>
              <DetailedCell.Description regular>{item.area}m²</DetailedCell.Description>
            </DetailedCell.Row>
            <DetailedCell.Row>
              <DetailedCell.Description medium>Available Area</DetailedCell.Description>
              <DetailedCell.Description regular>{item.areaOfConstruction}m²</DetailedCell.Description>
            </DetailedCell.Row>
          </DetailedCell.Root>
        );
      }
    },
    'show-houseLayout': () => {
      const details = getDetails(item);
      const expandedInformation = (
        <>
          <LayoutDetails title='Social Areas' data={details.social} />
          <LayoutDetails title='Intimate Areas' data={details.intimate} />
        </>
      );

      const onLayoutClick = () => {
        dispatchInteriorsAreas(item.id);
      };

      return (
        <DetailedCell.Root field={field} item={item} isSelected={isSelected} customFn={() => onLayoutClick()}>
          <div className='layout-details'>
            <div className='layout'>
              <DetailedCell.Title>Layout {item.index}</DetailedCell.Title>
              <DetailedCell.Row>
                <DetailedCell.Description regular>{parseFloat(details.area).toFixed(2)}m²</DetailedCell.Description>
              </DetailedCell.Row>
            </div>
            {isSelected && expandedInformation}
          </div>
        </DetailedCell.Root>
      );
    }
  };

  return getById[type]();
};

export default Integration;
