import { createSlice } from '@reduxjs/toolkit';

const imagesSlice = createSlice({
  name: 'images',
  initialState: {
    current: '',
    svg: ''
  },
  reducers: {
    setImage: (state, action) => {
      // console.log({ ...action.payload });
      const { hash, imageUrl } = action.payload;
      if (imageUrl) {
        // set current image from a new entry
        state.current = imageUrl;
        state[hash] = imageUrl;
      } else {
        // set current image from a preloaded entry
        state.current = state[hash];
      }
    },
    setSVG: (state, action) => {
      state.svg = action.payload.svg;
    }
  }
});

const imagesAction = imagesSlice.actions;
export { imagesSlice, imagesAction };
