import { useEffect, useState } from 'react';
//components
import Header from '@components/ui/Header/Header';
import { SVG } from '@assets/svg';
import Button from '@components/ui/Buttons/Button/Button';
//styles
import './dashboard.scss';
//translations
import { useTranslation } from 'react-i18next';
import TextInput from '@components/ui/Inputs/TextInput/TextInput';
//hooks
import { useNavigate } from 'react-router-dom';
import useRedux from '@hooks/useRedux';
import { useParams } from 'react-router-dom';
//services
import { createProject } from '@services/projects';
import Cookies from 'js-cookie';
import img1 from './tempPhotos/img1.png';
import img2 from './tempPhotos/img2.png';
import img3 from './tempPhotos/img3.png';
import img4 from './tempPhotos/img4.png';
import img5 from './tempPhotos/img5.png';
import img6 from './tempPhotos/img6.png';
import img7 from './tempPhotos/img7.png';
import img8 from './tempPhotos/img8.png';

const Dashboard = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [clientSearch, setClientSearch] = useState('');
  const [clientsList, setClientsList] = useState([]);
  const [projectsList, setProjectsList] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { dispatchProjectInfo } = useRedux();
  const sales_manager = Cookies.get('salesManager');
  const clientId = useParams()?.clientId;

  useEffect(() => {
    setClientsList(clientsData);
    setProjectsList(projectsData);
  }, []);

  useEffect(() => {
    if (clientSearch === '') {
      setClientsList(clientsData);
      setSelectedClient(null);
      return;
    }
  }, [clientSearch]);

  const handleClientSearch = client => {
    setClientSearch(client);
    const filteredClients = clientsData.filter(
      clientData =>
        clientData.name.toLowerCase().includes(client.toLowerCase()) ||
        clientData.email.toLowerCase().includes(client.toLowerCase())
    );
    setClientsList(filteredClients);
  };

  const handleClientSelection = (client, index) => {
    if (index === selectedClient) {
      setSelectedClient(null);
      setProjectsList(projectsData);
      setClientSearch('');
      return;
    }

    setSelectedClient(index);
    setClientSearch(client.name);
    const filteredProjects = projectsData.filter(projectData => projectData.client === client.name);
    setProjectsList(filteredProjects);
  };

  const handleClearClients = () => {
    setClientSearch('');
    setClientsList(clientsData);
    setProjectsList(projectsData);
  };

  const handleStartDesign = async () => {
    const projectBody = {
      project: 'TCT',
      sales_manager,
      client: clientsList[selectedClient].email,
      status: 'draft',
      form: {}
    };

    await createProject(projectBody)
      .then(response => {
        if (response) {
          console.log('Project saved to DB', response);
          dispatchProjectInfo(response);
          navigate(`/journey/${response.id}`);
        }
      })
      .catch(error => {
        console.log('Error saving project do DB', error);
      });
  };

  const projectsData = [
    {
      project: 'COMPORTA 01',
      image: img1,
      client: 'Alice Silva',
      bespokeId: '12345',
      date: '16/05/2024 h 15:22',
      plot: 'ABCD',
      house: '120m2',
      layout: 'Blocos Compacto'
    },
    {
      project: 'COMPORTA 02',
      image: img2,
      client: 'John Doe',
      bespokeId: '12345',
      date: '16/05/2024 h 15:22',
      plot: 'ABCD',
      house: '95m2',
      layout: 'Blocos Compacto'
    },
    {
      project: 'COMPORTA 03',
      image: img3,
      client: 'John Doe',
      bespokeId: '12345',
      date: '16/05/2024 h 15:22',
      plot: 'ABCD',
      house: '150m2',
      layout: 'Blocos Compacto'
    },
    {
      project: 'COMPORTA 04',
      image: img4,
      client: 'Maria Pereira',
      bespokeId: '12345',
      date: '16/05/2024 h 15:22',
      plot: 'ABCD',
      house: '200m2',
      layout: 'Blocos Compacto'
    },
    {
      project: 'COMPORTA 05',
      image: img5,
      client: 'David Smith',
      bespokeId: '12345',
      date: '16/05/2024 h 15:22',
      plot: 'ABCD',
      house: '120m2',
      layout: 'Blocos Compacto'
    },
    {
      project: 'COMPORTA 06',
      image: img6,
      client: 'Laura Gonzalez',
      bespokeId: '12345',
      date: '16/05/2024 h 15:22',
      plot: 'ABCD',
      house: '95m2',
      layout: 'Blocos Compacto'
    },
    {
      project: 'COMPORTA 07',
      image: img7,
      client: 'Laura Gonzalez',
      bespokeId: '12345',
      date: '16/05/2024 h 15:22',
      plot: 'ABCD',
      house: '150m2',
      layout: 'Blocos Compacto'
    },
    {
      project: 'COMPORTA 08',
      image: img8,
      client: 'Laura Gonzalez',
      bespokeId: '12345',
      date: '16/05/2024 h 15:22',
      plot: 'ABCD',
      house: '200m2',
      layout: 'Blocos Compacto'
    },
    {
      project: 'COMPORTA 09',
      image: img1,
      client: 'Nicolas Martin',
      bespokeId: '12345',
      date: '16/05/2024 h 15:22',
      plot: 'ABCD',
      house: '200m2',
      layout: 'Blocos Compacto'
    },
    {
      project: 'COMPORTA 10',
      image: img2,
      client: 'Alice Silva',
      bespokeId: '12345',
      date: '16/05/2024 h 15:22',
      plot: 'ABCD',
      house: '200m2',
      layout: 'Blocos Compacto'
    },
    {
      project: 'COMPORTA 11',
      image: img3,
      client: 'Nicolas Martins',
      bespokeId: '12345',
      date: '16/05/2024 h 15:22',
      plot: 'ABCD',
      house: '200m2',
      layout: 'Blocos Compacto'
    }
  ];

  const clientsData = [
    {
      name: 'Alice Silva',
      email: 'alice.silva@example.com',
      phone: '+55 11 98765-4321',
      nationality: 'Brazilian',
      configurations: 3
    },
    {
      name: 'John Doe',
      email: 'john.doe@example.com',
      phone: '+1 234 567-8901',
      nationality: 'American',
      configurations: 5
    },
    {
      name: 'Maria Pereira',
      email: 'maria.pereira@example.com',
      phone: '+55 21 99876-5432',
      nationality: 'Brazilian',
      configurations: 2
    },
    {
      name: 'David Smith',
      email: 'david.smith@example.com',
      phone: '+44 123 456-7890',
      nationality: 'British',
      configurations: 4
    },
    {
      name: 'Laura Gonzalez',
      email: 'laura.gonzalez@example.com',
      phone: '+34 912 345-678',
      nationality: 'Spanish',
      configurations: 1
    },
    {
      name: 'Nicolas Martin',
      email: 'nicolas.martin@example.com',
      phone: '+33 1 2345-6789',
      nationality: 'French',
      configurations: 6
    }
  ];

  return (
    <div className='dashboard-container'>
      <Header shouldDisplay isDark logoOnly />
      <div className='content-container'>
        <div className='projects-wrapper'>
          <div className='title-section'>
            <span className='title'>{t('dashboard.title')}</span>
            <span className='subtitle'>{t('dashboard.subtitle')}</span>
          </div>
          <div className='filter-section'>
            {/* <span className='sort'>{t('dashboard.sort')}: Most recent</span> */}
            <div style={{ display: 'flex', gap: '2px', alignItems: 'center' }}>
              <span className={'filter'}>{t('dashboard.filter')}:</span>
              <div className={`filter ${selectedClient || selectedClient === 0 ? 'byName' : ''}`}>
                {selectedClient || selectedClient === 0 ? clientsList[selectedClient]?.name : 'None'}
                <div onClick={handleClearClients} style={{ display: 'flex', alignItems: 'center' }}>
                  {(selectedClient || selectedClient === 0) && <SVG icon={'semantic/circle-x'} />}
                </div>
              </div>
            </div>
          </div>
          <div className={`projects-section ${isCollapsed ? 'full-width' : ''}`}>
            {(selectedClient || selectedClient === 0) && (
              <div className='new-design-card'>
                <div className='new-design-content'>
                  <SVG icon='logo/bespokeliving_grey' />
                  <div className='new-design-button'>
                    <Button
                      onClickFn={() => {
                        handleStartDesign();
                      }}
                      primary
                      text={t('dashboard.start_new_design')}
                    />
                  </div>
                </div>
              </div>
            )}
            {projectsList.map((project, index) => (
              <div key={index} className='project-card'>
                <div className='image-wrapper'>
                  <img src={project.image} alt='project' className='project-image' />
                </div>
                <div className='project-info'>
                  <span className='project-name'>{project.project}</span>
                  {Object.entries(project).map(([key, value]) => {
                    if (key === 'image' || key === 'project') return null;
                    return (
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span key={key} className='project-info-key'>
                          {key?.charAt(0)?.toUpperCase() + key?.slice(1)}
                        </span>
                        <span key={key} className='project-info-value'>
                          {value}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
          <div className={`clients-wrapper ${isCollapsed ? 'collapsed' : 'expanded'}`}>
            <div className='collapser-div'>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setIsCollapsed(!isCollapsed);
                }}
              >
                <SVG icon={!isCollapsed ? 'navigation/collapse' : 'navigation/expand'} />
              </div>
            </div>
            {!isCollapsed && (
              <>
                <div style={{ width: '100%', marginTop: '66px' }}>
                  <TextInput
                    value={clientSearch}
                    icon={'action/search'}
                    placeholder=''
                    type='search'
                    name='search'
                    id='search'
                    clearFn={handleClearClients}
                    label={t('dashboard.search')}
                    customCSS={{ height: '44px', width: '100%' }}
                    onChangeFn={e => {
                      handleClientSearch(e.target.value);
                    }}
                  />
                </div>
                <div className='clients-section'>
                  <span className='clients-title'>{t('dashboard.clients')}</span>
                  <div className='clients-cards'>
                    {clientsList.map((client, index) => (
                      <div
                        className={`client-card ${selectedClient === index ? 'selected' : ''}`}
                        onClick={() => {
                          handleClientSelection(client, index);
                        }}
                      >
                        <span className='client-name'>{client.name}</span>
                        {Object.entries(client).map(([key, value]) => {
                          if (key === 'name') return null;
                          return (
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <span key={key} className='client-info-key'>
                                {key?.charAt(0)?.toUpperCase() + key?.slice(1)}
                              </span>
                              <span key={key} className='client-info-value'>
                                {value}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
