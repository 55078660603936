import { Information as Information_Root } from './Information';
import Information_Title from './Information_Title';
import Information_Description from './Information_Description';

const Information = {
  Root: Information_Root,
  Title: Information_Title,
  Description: Information_Description
};

export default Information;
