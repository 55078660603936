import { Controller } from 'react-hook-form';

export const MaterialController = ({ control, currentStepId, division, question, onClickFn, Component }) => {
  const questionId = question.id;
  const questionType = question.type;
  const controllerId = `${currentStepId}.${questionId}.${division.controllerId}`;

  return (
    <Controller
      key={controllerId}
      name={controllerId}
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
        <Component
          type={questionType}
          field={field}
          division={division}
          controllerId={controllerId}
          onClickFn={onClickFn}
        />
      )}
    />
  );
};
