import DetailedCell from '@components/ui/Cells/DetailedCell';
import State from '@components/ui/State/State';
import useRender from '@hooks/useRender';
import useRedux from '@hooks/useRedux';

const TextWithImage = ({ type, field, item, renderCondition, controllerId }) => {
  const isSelected = useRender().isElementSelected({ controllerId, item });
  const isRecommended = useRedux().getTracking()?.recommendation?.houseType === item.id;

  return (
    <DetailedCell.Root field={field} item={item} renderCondition={renderCondition} isSelected={isSelected}>
      <div className='image-wrap'>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          {isRecommended ? (
            <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              <DetailedCell.Title>{item.label}</DetailedCell.Title>
              <State type='info' text='recommended' />
            </div>
          ) : (
            <DetailedCell.Title>{item.label}</DetailedCell.Title>
          )}

          <DetailedCell.Row>
            <DetailedCell.Description regular>{item.description}m²</DetailedCell.Description>
          </DetailedCell.Row>
        </div>

        <DetailedCell.Image url={item.image.url} altText={item.image.name} />
      </div>
    </DetailedCell.Root>
  );
};

export default TextWithImage;
