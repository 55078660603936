import { useState, useEffect } from 'react';
// components
import IconButton from '@components/ui/Buttons/IconButton/IconButton';
import LinkButton from '@components/ui/Buttons/LinkButton/LinkButton';
import Button from '@components/ui/Buttons/Button/Button';
import ProfileCell from '@components/ui/Cells/ProfileCell/ProfileCell';
import Tag from '@components/ui/Tag/Tag';
// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
// hooks
import useRedux from '@hooks/useRedux';
// styles
import './MultiChoiceModal.scss';

const formatJobTitle = jobTitle => {
  return jobTitle
    .toLowerCase()
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const MultiChoiceModal = ({ onClose }) => {
  const { getPartners } = useRedux();
  const [selectedTag, setSelectedTag] = useState('All');
  let profiles = getPartners();
  const [selected, setSelected] = useState([]);

  let tags = ['All'];
  profiles?.map(profile => {
    if (!tags.includes(formatJobTitle(profile.jobTitle))) tags.push(formatJobTitle(profile.jobTitle));
  });

  profiles = profiles.filter(profile => formatJobTitle(profile.jobTitle) === selectedTag || selectedTag === 'All');

  return (
    <Swiper
      watchSlidesProgress={true} // enables to see next or previous slide
      slidesPerView={'auto'} // max os slides per view
      centeredSlides={true}
      spaceBetween={40}
      pagination={{
        clickable: true,
        dynamicBullets: false
      }}
      modules={[Navigation, Pagination]}
      className='multi-choice-modal-swiper'
      onSlideChange={e => console.log('slide change', e)}
    >
      <SwiperSlide className='multi-choice-modal-swiper-slide'>
        <div className='multi-choice-modal-slide-content'>
          <div className='multi-choice-modal-slide-text'>
            <div className='multi-choice-modal-text-header'>
              {/* {<LinkButton ghost icon='navigation/arrow-left' text='Back' onClickFn={onClose}></LinkButton>} */}
              <IconButton
                className='multi-choice-modal-close'
                size={'small'}
                onImageBg={false}
                icon={'semantic/close'}
                onClickFn={onClose}
              />
            </div>
            <span className='multi-choice-modal-title'>Select the experts you wish to be contacted by.</span>
            <span className='multi-choice-modal-description'>
              Here is a comprehensive list of all our expert partners. Please feel free to select any that you believe
              would be relevant to your needs.
            </span>
          </div>
          <div className='multi-choice-modal-content'>
            <div className='multi-choice-modal-content-header'>
              <div className='multi-choice-modal-tag'>
                {tags.map(tag => {
                  return <Tag label={tag} selected={selectedTag} onClickFn={() => setSelectedTag(tag)}></Tag>;
                })}
              </div>
              <LinkButton text='Deselect all' onClickFn={() => setSelected([])}></LinkButton>
            </div>
            <div className='multi-choice-modal-profile'>
              {profiles.map(profile => {
                return (
                  <ProfileCell
                    profile={profile}
                    onSelect={() => setSelected([...selected, profile])}
                    onDeselect={() => setSelected(selected.filter(item => item !== profile))}
                    isProfileSelected={selected.includes(profile)}
                  ></ProfileCell>
                );
              })}
            </div>
          </div>
          <div className='multi-choice-modal-footer'>
            <Button primary text='Request Contact'></Button>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default MultiChoiceModal;
