import Stepper from '../../ui/Stepper/Stepper';
import './PanelHeader.scss';

const PanelHeader = ({ stepsNames, currentStepNr, onStepClick }) => {
  return (
    <div className='form-header'>
      <Stepper stepsNames={stepsNames} currentStepNr={currentStepNr} onStepClick={onStepClick} />
    </div>
  );
};

export default PanelHeader;
