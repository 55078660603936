// styles
import './Tag.scss';

const Tag = ({ label, selected, onClickFn }) => {
  const TagClass = `
    ${selected === label ? 'tag-selected' : 'tag'} 
  `;

  return (
    <span className={`${TagClass.trim()} label`} onClick={onClickFn}>
      {label}
    </span>
  );
};

export default Tag;
