export const NATIONALITY_OPTIONS = [
  'Afghan',
  'Albanian',
  'Algerian',
  'Andorran',
  'Angolan',
  'Antiguan or Barbudan',
  'Argentine',
  'Armenian',
  'Australian',
  'Austrian',
  'Azerbaijani',
  'Bahamian',
  'Bahraini',
  'Barbadian',
  'Basotho',
  'Belarusian',
  'Belgian',
  'Belizean',
  'Bengali',
  'Beninese',
  'Bhutanese',
  'Bissau-Guinean',
  'Bolivian',
  'Bosnian or Herzegovinian',
  'Brazilian',
  'British',
  'Bruneian',
  'Bulgarian',
  'Burkinabé',
  'Burmese',
  'Burundian',
  'Cabo Verdean',
  'Cambodian',
  'Cameroonian',
  'Canadian',
  'Central African',
  'Chadian',
  'Chilean',
  'Chinese',
  'Colombian',
  'Comoran, Comorian',
  'Congolese',
  'Costa Rican',
  'Croatian',
  'Cuban',
  'Cypriot',
  'Czech',
  'Danish',
  'Djiboutian',
  'Dominican',
  'Dutch, Netherlandic',
  'Ecuadorian',
  'Egyptian',
  'Emirati, Emirian, Emiri',
  'Equatorial Guinean, Equatoguinean',
  'Eritrean',
  'Estonian',
  'Ethiopian',
  'Fijian',
  'Filipino, Philippine',
  'Finnish',
  'French',
  'Gabonese',
  'Gambian',
  'Georgian',
  'German',
  'Ghanaian',
  'Gibraltar',
  'Greek, Hellenic',
  'Grenadian',
  'Guatemalan',
  'Guinean',
  'Guyanese',
  'Haitian',
  'Honduran',
  'Hungarian, Magyar',
  'Icelandic',
  'I-Kiribati',
  'Indian',
  'Indonesian',
  'Iranian, Persian',
  'Iraqi',
  'Irish',
  'Israeli',
  'Italian',
  'Ivorian',
  'Jamaican',
  'Japanese',
  'Jordanian',
  'Kazakhstani, Kazakh',
  'Kenyan',
  'Kittitian or Nevisian',
  'Kuwaiti',
  'Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz',
  'Lao, Laotian',
  'Latvian, Lettish',
  'Lebanese',
  'Liberian',
  'Libyan',
  'Liechtensteiner',
  'Lithuanian',
  'Luxembourg, Luxembourgish',
  'Macedonian',
  'Malagasy',
  'Malawian',
  'Malaysian',
  'Maldivian',
  'Malian, Malinese',
  'Maltese',
  'Marshallese',
  'Martiniquais, Martinican',
  'Mauritanian',
  'Mauritian',
  'Mexican',
  'Micronesian',
  'Moldovan',
  'Monégasque, Monacan',
  'Mongolian',
  'Montenegrin',
  'Moroccan',
  'Motswana, Botswanan',
  'Mozambican',
  'Namibian',
  'Nauruan',
  'Nepali, Nepalese',
  'New Zealand, NZ, Zelanian',
  'Nicaraguan',
  'Nigerian',
  'Ni-Vanuatu, Vanuatuan',
  'Northern Marianan',
  'North Korean',
  'Norwegian',
  'Omani',
  'Pakistani',
  'Palauan',
  'Palestinian',
  'Panamanian',
  'Papua New Guinean, Papuan',
  'Paraguayan',
  'Peruvian',
  'Polish',
  'Portuguese',
  'Puerto Rican',
  'Qatari',
  'Romanian',
  'Russian',
  'Rwandan',
  'Saint Lucian',
  'Saint Vincentian, Vincentian',
  'Salvadoran',
  'Sammarinese',
  'Samoan',
  'São Toméan',
  'Saudi, Saudi Arabian',
  'Senegalese',
  'Serbian',
  'Seychellois',
  'Sierra Leonean',
  'Singapore, Singaporean',
  'Slovak',
  'Slovenian, Slovene',
  'Solomon Island',
  'Somali',
  'South African',
  'South Korean',
  'South Sudanese',
  'Spanish',
  'Sri Lankan',
  'Sudanese',
  'Surinamese',
  'Swazi',
  'Swedish',
  'Swiss',
  'Syrian',
  'Tajikistani',
  'Tanzanian',
  'Thai',
  'Timorese',
  'Togolese',
  'Tokelauan',
  'Tongan',
  'Trinidadian or Tobagonian',
  'Tunisian',
  'Turkish',
  'Turkmen',
  'Tuvaluan',
  'Ugandan',
  'Ukrainian',
  'United States, U.S., American',
  'Uruguayan',
  'Uzbekistani, Uzbek',
  'Vatican',
  'Venezuelan',
  'Vietnamese',
  'Yemeni',
  'Zambian',
  'Zimbabwean'
];

export const LANGUAGES_OPTIONS = [
  'Afrikaans',
  'Albanian',
  'Amharic',
  'Arabic',
  'Arabiv',
  'Bengali',
  'Bulgarian',
  'Cambodian',
  'Chinese',
  'Creole',
  'Croatian',
  'Czech',
  'Danish',
  'Dari (Persian) - Pashto',
  'Dutch',
  'English',
  'Estonian',
  'Fijian',
  'Filipino',
  'Finnish',
  'Flemish',
  'French',
  'German',
  'Greek',
  'Hebrew',
  'Hindi',
  'Hungarian',
  'Icelandic',
  'Indonesian',
  'Irish',
  'Italian',
  'Japanese',
  'Korean',
  'Laotian',
  'Latvian',
  'Lithuanian',
  'Malay',
  'Malaysian',
  'Maltese',
  'Mandarin',
  'Maori',
  'Mongolian',
  'Nepali',
  'Norwegian',
  'Persian',
  'Polish',
  'Portuguese',
  'Romanian',
  'Romansh',
  'Russian',
  'Serbian',
  'Setswana',
  'Sinhala',
  'Slovak',
  'Spanish',
  'Swahili',
  'Swedish',
  'Tagalog',
  'Tajik (Persian)',
  'Tamil',
  'Thai',
  'Tongan',
  'Turkish',
  'Ukrainian',
  'Urdu',
  'Vietnamese',
  'Welsh'
];

export const PARTNERS_OPTIONS = [
  'AmberStar',
  'Benoit',
  'Castelhana',
  'Century21',
  'Exclusive',
  'JLL',
  'Porta da Frente',
  'Quintela e Penalva',
  'Remax',
  'Savills',
  'Second Home',
  'Sothebys',
  'Other'
];
