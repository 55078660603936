import { PLOTS_INITIAL_NR } from 'config';

export const getNrQuestionToDisplay = ({ isPlotQuestion, question, questionIndex, nrAnswersToDisplay }) => {
  // Determine how many answers to display
  const answersToDisplay = isPlotQuestion
    ? [{ items: question.answers[0].items?.slice(0, nrAnswersToDisplay[questionIndex]) }]
    : question.answers; // For other question types, show all answers

  return answersToDisplay;
};

export const getInitialNumberAnswers = ({ questions }) => {
  return questions.map(q => q.id === 'plot' && PLOTS_INITIAL_NR);
};
