import useRules from './useRules';
import { useFormContext } from 'react-hook-form';

const useRender = () => {
  const { RULES, RULES_QUESTIONS } = useRules();
  const { watch } = useFormContext(); // Access the shared form instance via useFormContext
  const watcher = watch();

  const itemShouldRender = (renderCondition, item) => {
    if (renderCondition && RULES[renderCondition]) {
      return RULES[renderCondition]({ item });
    } else {
      return true;
    }
  };

  const innerQuestionShouldRender = ({ currentStepId, questionId, answerIndex }) => {
    if (currentStepId === 'exteriors' && questionId === 'pool' && answerIndex !== 0) {
      return RULES['poolDetails']();
    } else {
      return true;
    }
  };

  const questionShouldRender = renderCondition => {
    if (renderCondition && RULES_QUESTIONS[renderCondition]) {
      return RULES_QUESTIONS[renderCondition]();
    } else {
      return true;
    }
  };

  const getWatcherName = controllerId => {
    /**
     * Return the value of a given key, even if nested, passing the path separeted by dots
     * Example: root property -> "name"
     * Example: nested property -> "obj.name"
     **/

    return controllerId?.split('.').reduce((acc, part) => acc && acc[part], watcher);
  };

  const isElementSelected = ({ controllerId, item }) => {
    return getWatcherName(controllerId) === item?.id;
  };

  return {
    itemShouldRender,
    innerQuestionShouldRender,
    questionShouldRender,
    getWatcherName,
    isElementSelected
  };
};

export default useRender;
