import { pricingAction } from './pricing-slice';
import { getPricing } from '@services/pricing';

export const setPrice = async (dispatch, formState) => {
  try {
    const response = await getPricing(formState);
    dispatch(pricingAction.setPrice(response.price));
  } catch (error) {
    console.log('ERROR');
  } finally {
  }
};

export const resetPrice = async (dispatch, queryParams) => {
  try {
    dispatch(pricingAction.resetPrice());
  } catch (error) {
    console.log('ERROR');
  } finally {
  }
};
