import React, { useEffect, useState } from 'react';
//components
import Header from '@components/ui/Header/Header';
import OTPInput from '@components/ui/OTPInput/OTPInput';
//styles
import './otp.scss';
//hooks
import { useNavigate } from 'react-router-dom';
import image from './verifyPageImage.png';
import useRedux from '@hooks/useRedux';
//translations
import { useTranslation } from 'react-i18next';
//services
import { registerClient } from '@services/client';
import Cookies from 'js-cookie';
//utils
import { isEmpty } from '@utils/functions';

const VerifyCodePage = ({}) => {
  const navigate = useNavigate();
  const sales_manager = Cookies.get('salesManager');
  const sales_manager_token = Cookies.get('sessionId');
  const { t } = useTranslation();
  const [timer, setTimer] = useState(30);
  const [errorMessage, setErrorMessage] = useState(null);
  const { dispatchNotification, getClient } = useRedux();

  const clientData = getClient();

  const resendCode = () => {
    setTimer(30);
    startTimer();
  };

  const startTimer = () => {
    const count = setInterval(() => {
      setTimer(prevCount => {
        if (prevCount <= 0) {
          clearInterval(count);
          return 0;
        }
        return prevCount - 1;
      });
    }, 1000);

    return () => clearInterval(count);
  };

  useEffect(() => {
    startTimer();
  }, []);

  const onCompleteFunction = async numbers => {
    try {
      const data = {
        clientType: 'Customer',
        firstName: clientData.name,
        lastName: clientData.surname,
        email: clientData.email,
        phone: clientData.phone,
        nationality: clientData.nationality, //ver lista de valores partilhada
        language: clientData.language, //ver lista de valores partilhada, opcional
        RGDP_Marketing: clientData.marketing, //falta um campo de RGPD: RGPD Pedidos de Informação
        RGPD_PedidosDeInformacao: clientData.marketing,
        newsletter: clientData.newsletter, //opcional
        hasOptedOutOfEmail: false, // corrigir
        do_Not_Email: false, // corrigir
        sales_manager_email: sales_manager,
        brokerage: clientData.brokerage === 'Yes',
        agentName: clientData.agentName,
        partnerName: clientData.partner !== 'Other' ? clientData.partner : '',
        partnerNameOther: clientData.partnerName,
        partnerEmail: isEmpty(clientData.agentEmail) ? undefined : clientData.agentEmail,
        partnerPhone:
          isEmpty(clientData.agentPhone) || clientData.agentPhone === '+' ? undefined : clientData.agentPhone
      };

      console.log('DATA: ', data);

      const response = await registerClient(data, sales_manager_token);
      dispatchNotification({
        status: 'success',
        title: t('otp.verifyPage.registrationSuccess')
      });
      navigate(`/journey/${response?.beSpokeId}`);
    } catch (error) {
      setErrorMessage(t('otp.verifyPage.errorMessage'));
    }
  };

  return (
    <div className='otp-verify-container'>
      <Header shouldDisplay isDark />
      <div className='otp-verify-wrapper'>
        <img src={image} className='background-image' />
        <div className='content-wrapper'>
          <div className='content-section'>
            <div className='title-container'>
              <span className='main-title'>{t('otp.verifyPage.title')}</span>
              <span className='sub-title'>{t('otp.verifyPage.subTitle')}</span>
            </div>
            <OTPInput
              containerStyle={'input-section'}
              inputNormalStyle={'individual-input'}
              inputErrorStyle={'individual-input-error'}
              length={6}
              onComplete={numbers => onCompleteFunction(numbers)}
              onChange={() => setErrorMessage(null)}
              errorMessage={errorMessage}
            />
            <div className='cta-container'>
              <span className='didnt_get_code'>{t('otp.verifyPage.didnt_get_code')}</span>
              {timer > 0 ? (
                <span className='new-code'>
                  {t('otp.verifyPage.ask_new')} {timer}s
                </span>
              ) : (
                <span onClick={resendCode} className='resend'>
                  {t('otp.verifyPage.resend')}
                </span>
              )}
              <span className='change-service'>{t('otp.verifyPage.change_ver_service')}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyCodePage;
