import { modalsAction } from './modals-slice';
import { getModals } from '@services/modals';

export const setModals = async dispatch => {
  try {
    const response = await getModals();
    dispatch(modalsAction.setModals(response));
  } catch (error) {
    console.log('ERROR');
  } finally {
  }
};
