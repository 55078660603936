import * as rules from './rules';
import { isObject, isArray } from './utils';

const updateRedux = ({ useRedux, reduxFn, updatedObject }) => {
  if (reduxFn) {
    const { dispatchUpdateRefDataById } = useRedux;

    const { method, key } = reduxFn;
    if (method === 'updateRefData') {
      dispatchUpdateRefDataById({ idToUpdate: key, updatedObject });
    }
  }
};

export const functionLoader = (useRedux, question, selectedValue) => {
  const { rawData, refData } = useRedux;
  const { actions } = question;

  const DATA_MAPPER = {
    rawData: rawData,
    refData: refData
  };

  let filteredData;

  if (isObject(actions)) {
    // Single Action to be triggered
    const { filter, reduxFn } = actions;
    const { dataset, method, object } = filter;

    // Trigger the function from questions.filter.type
    filteredData = rules[method]({
      data: DATA_MAPPER[dataset],
      object: object,
      selectedValue: selectedValue
    });

    updateRedux({ useRedux, reduxFn, updatedObject: filteredData });
  }

  if (isArray(actions)) {
    // Multiple Actions to be triggered
    actions.forEach(action => {
      const { filter, reduxFn } = action;
      const { dataset, method, object } = filter;
      // Trigger the function from questions.filter.type
      filteredData = rules[method]({
        data: DATA_MAPPER[dataset],
        object: object,
        selectedValue: selectedValue
      });
      updateRedux({ useRedux, reduxFn, updatedObject: filteredData });
    });
  }
};

export const isEmpty = element => {
  if (Array.isArray(element)) {
    return element.length === 0;
  } else if (element && typeof element === 'object') {
    return Object.keys(element).length === 0;
  } else if (typeof element == 'string' || element instanceof String) {
    return element?.trim() === '';
  } else if (typeof element !== 'undefined' && element !== null && !isNaN(element) && element !== '') {
    return false;
  } else {
    return null;
  }
};

export const isValidEmail = email => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};
