import { createSlice } from '@reduxjs/toolkit';

const filtersSlice = createSlice({
  name: 'filters',
  initialState: {},
  reducers: {
    setFilters: (state, action) => {
      return { ...action.payload };
    },
    reset: () => {
      return {};
    }
  }
});

const filtersActions = filtersSlice.actions;
export { filtersSlice, filtersActions };
