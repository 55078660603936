import React, { useEffect, useRef, useState, useCallback } from 'react';
import OpenSeadragon from 'openseadragon';
import * as d3 from 'd3';
import './Map.scss'; // Import the CSS styles separately
import PLOTS_DATA from '@assets/polygons/plotsData.json';

const Map = ({ svg }) => {
  const viewerRef = useRef(null);
  const tooltipRef = useRef(null);
  const viewerInstance = useRef(null); // Use ref to store the OpenSeadragon instance
  const [polygonsData] = useState(PLOTS_DATA); // Load polygons data from imported JSON

  // Utility for debouncing
  function debounce(func, delay) {
    let timeoutId;
    return function (...args) {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(this, args), delay);
    };
  }

  // Resize handler
  const handleResize = useCallback(
    debounce(() => {
      if (viewerInstance.current) {
        viewerInstance.current.viewport.goHome(true);
        viewerInstance.current.viewport.applyConstraints();
      }
    }, 100),
    []
  );

  useEffect(() => {
    const initializeViewer = () => {
      try {
        const svgText = svg;
        // Initialize OpenSeadragon viewer
        viewerInstance.current = OpenSeadragon({
          id: viewerRef.current.id,
          prefixUrl: 'openseadragon-bin-5.0.0/images/',
          tileSources: {
            type: 'image',
            url: '/img/baselayerWIP.png', // Replace with your image path
            // url: 'img/baselayer_big.dzi', // Replace with your image path
            buildPyramid: false,
            width: 960, // Set the actual image width (5578)
            height: 945 // Set the actual image height (4228)
          },
          // tileSources: '/img/baselayer_big.dzi', // Path to DZI file
          showNavigator: false,
          showNavigationControl: false,
          constrainDuringPan: true,
          homeFillsViewer: true, // Force the image to always fill the viewer container
          minZoomLevel: 1, // This will be set dynamically later
          maxZoomLevel: 10, // Maximum zoom level
          visibilityRatio: 1, // Ensure entire image is always visible
          // Disable zoom on single click
          zoomPerClick: 1,

          // Disable zoom on double-click
          dblClickZoom: false
        });

        // Ensure viewer instance is created
        // console.log('OpenSeadragon viewer instance:', viewerInstance.current);

        viewerInstance.current.addHandler('open', () => {
          handleResize();
          // console.log('Image loaded successfully');

          // Get image dimensions
          const tiledImage = viewerInstance.current.world.getItemAt(0);
          const imageSize = tiledImage.getContentSize();
          // console.log('Image dimensions:', imageSize.x, 'x', imageSize.y);

          // Create SVG overlay
          const svgNamespace = 'http://www.w3.org/2000/svg';
          const parser = new DOMParser();
          const svgDoc = parser.parseFromString(svgText, 'image/svg+xml');
          const svgElement = svgDoc.documentElement;

          // Set SVG dimensions
          svgElement.setAttribute('width', imageSize.x);
          svgElement.setAttribute('height', imageSize.y);

          // Create container for SVG overlay
          const overlayDiv = document.createElement('div');
          overlayDiv.className = 'overlay-svg-container';
          overlayDiv.appendChild(svgElement);

          // Add overlay to viewer's canvas
          viewerInstance.current.canvas.appendChild(overlayDiv);

          // D3 selection for easier manipulation
          const svgSelection = d3.select(svgElement);

          // Style polygons (paths) and add interactivity
          svgSelection
            .selectAll('path')
            .style('fill', function () {
              const id = d3.select(this).attr('id');
              const data = polygonsData[id];
              return data && data.status === 'available' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)';
            })
            .style('stroke', 'white')
            .style('stroke-width', '2px')
            .style('cursor', 'pointer')
            .style('stroke-dasharray', '8,4')
            .on('mouseover', function (event) {
              const id = d3.select(this).attr('id');
              const data = polygonsData[id];
              if (data) {
                d3.select(this).style('fill', 'rgba(255, 255, 255, 0.8)');

                // Show tooltip
                const tooltip = tooltipRef.current;
                tooltip.innerHTML = `<strong>${data.name}</strong><br>${data.status}`;
                tooltip.style.opacity = 1;

                // Position tooltip near the mouse cursor
                tooltip.style.left = `${event.pageX + 10}px`;
                tooltip.style.top = `${event.pageY + 10}px`;
              }
            })
            .on('mousemove', function (event) {
              // Update tooltip position
              const tooltip = tooltipRef.current;
              tooltip.style.left = `${event.pageX + 10}px`;
              tooltip.style.top = `${event.pageY + 10}px`;
            })
            .on('mouseout', function () {
              const id = d3.select(this).attr('id');
              const data = polygonsData[id];
              if (data) {
                d3.select(this).style('fill', 'rgba(255, 255, 255, 0.5)');

                // Hide tooltip
                const tooltip = tooltipRef.current;
                tooltip.style.opacity = 0;
              }
            });

          // Synchronize overlay with viewer's viewport
          viewerInstance.current.addHandler('animation', updateOverlay);
          viewerInstance.current.addHandler('resize', updateOverlay);
          updateOverlay();

          // Function to update the overlay's viewBox based on the current viewport
          function updateOverlay() {
            const bounds = viewerInstance.current.viewport.getBounds(true);
            const imageRectangle = viewerInstance.current.viewport.viewportToImageRectangle(bounds);
            svgElement.setAttribute(
              'viewBox',
              `${imageRectangle.x} ${imageRectangle.y} ${imageRectangle.width} ${imageRectangle.height}`
            );
          }

          // Set minimum zoom level to 1
          // viewerInstance.current.viewport.setMinZoomLevel(1);
          viewerInstance.current.viewport.zoomTo(1); // Change '2' to your desired initial zoom level
        });

        viewerInstance.current.addHandler('open-failed', event => {
          console.error('OpenSeadragon failed to open the image:', event);
        });
      } catch (error) {
        console.error('Error during initialization:', error);
      }
    };

    initializeViewer();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [polygonsData, svg, handleResize]);

  // Custom zoom control functions
  const zoomIn = () => {
    if (viewerInstance.current) {
      viewerInstance.current.viewport.zoomBy(1.2);
      viewerInstance.current.viewport.applyConstraints();
    }
  };

  const zoomOut = () => {
    if (viewerInstance.current) {
      viewerInstance.current.viewport.zoomBy(1 / 1.2);
      viewerInstance.current.viewport.applyConstraints();
    }
  };

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <div id='viewerContainer' ref={viewerRef} style={{ width: '100%', height: '100%' }} />
      <div className='custom-zoom-controls'>
        <button className='zoom-button' onClick={zoomIn}>
          +
        </button>
        <button className='zoom-button' onClick={zoomOut}>
          -
        </button>
      </div>
      <div ref={tooltipRef} className='tooltip' />
    </div>
  );
};

export default Map;
