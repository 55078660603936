import { useState, useEffect, useRef } from 'react';
// components
import LinkButton from '@components/ui/Buttons/LinkButton/LinkButton';
import TextInput from '@components/ui/Inputs/TextInput/TextInput';
import Accordion from '@components/ui/Accordion/Accordion';
import Portal from '@components/ui/Overlays/Portal/Portal';
import Dialog from '@components/ui/Overlays/Dialog/Dialog';
import PriceCard from '@components/ui/Cards/PriceCard/PriceCard';
import { useNavigate } from 'react-router-dom';
// hooks
import { useFormContext } from 'react-hook-form';
import useRedux from '@hooks/useRedux';
// utils
import { DIALOG_TYPE } from '@utils/enums';
// styles
import './review.scss';

const convertNumberToArea = number => {
  if (typeof number === 'number') {
    const numberString = number.toString();
    const areaString = numberString.replace(/\./g, ',');
    const area = areaString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return `${area}m²`;
  }
};

const convertIdToLabel = id => {
  const versionMatch = id.match(/v(\d+)/);
  if (versionMatch) {
    const versionNumber = parseInt(versionMatch[1], 10);
    return `Layout ${versionNumber}`;
  }
};

const getDetails = modules => {
  const finale = {
    layout: [],
    area: 0
  };
  let social = [];
  let social_others = 0;
  let intimate = [];
  let intimate_others = 0;
  modules?.forEach(module => {
    module.divisions?.forEach(division => {
      finale.area = finale.area + division.area;
      if (division.category === 'Area Social' && division.type !== 'Outros') social.push(division);
      if (division.category === 'Area Social' && division.type === 'Outros')
        social_others = social_others + division.area;
      if (division.category === 'Area Intima' && division.type !== 'Outros') intimate.push(division);
      if (division.category === 'Area Intima' && division.type === 'Outros')
        intimate_others = intimate_others + division.area;
    });
  });
  finale.area = parseFloat(finale.area.toFixed(2));

  finale.layout.push({ title: 'Social Area' });
  social.map(area => finale.layout.push({ label: area.label, value: convertNumberToArea(area.area) }));
  if (social_others > 0)
    finale.layout.push({ label: 'Others', value: convertNumberToArea(parseFloat(social_others.toFixed(2))) });
  finale.layout.push({ title: 'Intimate Area' });
  intimate.map(area => finale.layout.push({ label: area.label, value: convertNumberToArea(area.area) }));
  if (intimate_others > 0)
    finale.layout.push({ label: 'Others', value: convertNumberToArea(parseFloat(intimate_others.toFixed(2))) });

  return finale;
};

const Review = ({ formState, reviewData, journeyObj, setCustomFormStep, setProjectSubmittedPageOpen, children }) => {
  const { questions } = journeyObj;
  const {
    getPlots,
    getMacroplots,
    getHouseTypes,
    getHouses,
    getPacks,
    getTracking,
    getForms,
    dispatchSetDialog,
    FORMSTATE
  } = useRedux();
  const plots = getPlots();
  const macroplots = getMacroplots();
  const packs = getPacks();
  const pools = getForms().journey[2].questions[1].answers;
  const parking = getForms().journey[2].questions[2].answers;
  const nrRooms = getForms().journey[1].questions[0].answers[0].items;
  let mastersuites = [];
  let suites = [];
  let socialAreas = [];
  let appliances = [];

  const [projectName, setProjectName] = useState('Project 1');
  const [isDialogOpen, setIsDialogOpen] = useState(getTracking().dialog.open);
  const dialogRef = useRef(null);

  const handleChange = e => {
    setProjectName(e.target.value);
  };

  if (!FORMSTATE.get('stepsFinished').includes('Review')) FORMSTATE.dispatchUpdateStepFinished('Review');

  Object.keys(getTracking().interiorsAreas).forEach(key => {
    if (key === 'intimate-areas') {
      mastersuites = getTracking().interiorsAreas[key].mastersuites;
      suites = getTracking().interiorsAreas[key].suites;
    } else if (key === 'social-areas') {
      socialAreas = getTracking().interiorsAreas[key];
    } else if (key === 'kitchen') {
      appliances = getTracking().interiorsAreas[key].kitchens[0].appliances.appliances;
    }
  });

  const { watch } = useFormContext(); // Access the shared form instance via useFormContext
  const watcher = watch();

  const getLayout = () => {
    const house = getHouses().filter(house => house.id === watcher?.layout.houseLayout);
    const details = getDetails(house[0].modules?.combinations);
    return details;
  };

  useEffect(() => {
    if (getTracking()?.dialog?.open) {
      setIsDialogOpen(true);
    }
  }, [getTracking().dialog]);

  // Close modal on 'esc' key press
  useEffect(() => {
    const handleEscKey = event => {
      if (event.key === 'Escape') {
        setIsDialogOpen(!isDialogOpen);
        dispatchSetDialog({ open: false, type: '', id: '' });
      }
    };
    if (isDialogOpen) window.addEventListener('keydown', handleEscKey);
    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [isDialogOpen]);

  // Close modal on click outside the modal
  useEffect(() => {
    const handleClickOutside = event => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        setIsDialogOpen(!isDialogOpen);
        dispatchSetDialog({ open: false, type: '', id: '' });
      }
    };
    if (isDialogOpen) window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDialogOpen]);

  const getIntimateAreas = () => {
    let intimateAreas = [];
    let counter = 0;
    Object.keys(watcher?.interiors.intimateAreas.mastersuite).forEach(key => {
      intimateAreas.push({
        label: mastersuites[counter].suite.title,
        value: packs.filter(pack => pack.id === watcher?.interiors.intimateAreas.mastersuite[key].suite)[0].label
      });
      counter++;
    });
    counter = 0;
    Object.keys(watcher?.interiors.intimateAreas.suites).forEach(key => {
      intimateAreas.push({
        label: suites[counter].suite.title,
        value: packs.filter(pack => pack.id === watcher?.interiors.intimateAreas.suites[key].suite)[0].label
      });
      counter++;
    });
    return intimateAreas;
  };

  const getBathrooms = () => {
    let bathrooms = [];
    let counter = 0;
    bathrooms.push({
      label: socialAreas.filter(area => area.type === 'WC')[0].label,
      value: packs.filter(pack => pack.id === watcher?.interiors.socialAreas.bathroom)[0].label
    });
    Object.keys(watcher?.interiors.intimateAreas.mastersuite).forEach(key => {
      bathrooms.push({
        label: mastersuites[counter].bathroom.title,
        value: packs.filter(pack => pack.id === watcher?.interiors.intimateAreas.mastersuite[key].bathroom)[0].label
      });
      counter++;
    });
    counter = 0;
    Object.keys(watcher?.interiors.intimateAreas.suites).forEach(key => {
      bathrooms.push({
        label: suites[counter].bathroom.title,
        value: packs.filter(pack => pack.id === watcher?.interiors.intimateAreas.suites[key].bathroom)[0].label
      });
      counter++;
    });
    return bathrooms;
  };

  return (
    <div className='review'>
      <div className='top-section'>
        <span className='headline'>{questions[0].headline}</span>
        {questions[0].description && <span className='description'>{questions[0].description}</span>}
      </div>
      <TextInput
        label='Configuration name'
        icon='action/tag'
        placeholder='Project 1'
        value={projectName}
        onChangeFn={handleChange}
      />
      <div className='review__container'>
        <span>Bespoke Home</span>
        <div className='review__step-container'>
          <div className='review__step-container-header'>
            <span className='review__step-container-title'>Your plot</span>
            <LinkButton
              text='Edit'
              onClickFn={() => {
                setCustomFormStep(0); // TODO dinâmico
              }}
            ></LinkButton>
          </div>
          <div className='review__step-container-line'>
            <span>Macroplot</span>
            <span>{macroplots.filter(macroplot => macroplot.id === watcher?.plot.macroplot)[0].label}</span>
          </div>
          <div className='review__step-container-line'>
            <span>Plot</span>
            <span>
              {plots.filter(plot => plot.id === watcher?.plot.plot)[0].label} -{' '}
              {convertNumberToArea(plots.filter(plot => plot.id === watcher?.plot.plot)[0].area)}
            </span>
          </div>
          <div className='review__step-container-line'>
            <span>Area of Construction Capacity</span>
            <span>
              {convertNumberToArea(plots.filter(plot => plot.id === watcher?.plot.plot)[0].areaOfConstruction)}
            </span>
          </div>
        </div>
        <div className='review__step-container'>
          <div className='review__step-container-header'>
            <span className='review__step-container-title'>Your home layout</span>
            <LinkButton
              text='Edit'
              onClickFn={() => {
                setCustomFormStep(1); // TODO dinâmico
              }}
            ></LinkButton>
          </div>
          <div className='review__step-container-line'>
            <span>House Type</span>
            <span>{getHouseTypes().filter(houseType => houseType.id === watcher?.layout.houseType)[0].label}</span>
          </div>
          <div className='review__step-container-line'>
            <span>Bedrooms</span>
            <span>{nrRooms.filter(room => room.id === watcher?.layout.nrRooms)[0].label}</span>
          </div>
          <Accordion
            label={convertIdToLabel(watcher?.layout.houseLayout)}
            description={convertNumberToArea(getLayout().area)}
            content={getLayout().layout}
          ></Accordion>
        </div>
        <div className='review__step-container'>
          <div className='review__step-container-header'>
            <span className='review__step-container-title'>Outside looks</span>
            <LinkButton
              text='Edit'
              onClickFn={() => {
                setCustomFormStep(2); // TODO dinâmico
              }}
            ></LinkButton>
          </div>
          {watcher?.exteriors.pool.poolSize === 'none' ? (
            <div className='review__step-container-line'>
              <span>Swimming Pool</span>
              <span>No</span>
            </div>
          ) : (
            <Accordion
              label='Swimming Pool'
              content={[
                {
                  label: 'Size',
                  value: pools[0].items.filter(item => item.id === watcher?.exteriors.pool.poolSize)[0].label
                },
                {
                  label: 'Coating',
                  value: getPacks().find(pack => pack.id === watcher?.exteriors.pool.poolCovering).label
                },
                {
                  label: 'Heated pool',
                  value: pools[2].items.filter(item => item.id === watcher?.exteriors.pool.poolHeated)[0].label
                }
              ]}
            ></Accordion>
          )}
          <div className='review__step-container-line'>
            <span>Parking area</span>
            <span>
              {parking[0].items.filter(item => item.id === watcher?.exteriors.parking.parkingType)[0].label} -{' '}
              {parking[1].items.filter(item => item.id === watcher?.exteriors.parking.parkingSpace)[0].label}
            </span>
          </div>
        </div>
        <div className='review__step-container'>
          <div className='review__step-container-header'>
            <span className='review__step-container-title'>Interiors</span>
            <LinkButton
              text='Edit'
              onClickFn={() => {
                setCustomFormStep(3); // TODO dinâmico
              }}
            ></LinkButton>
          </div>
          <Accordion
            label='Social Area'
            content={[
              {
                label: 'Theme',
                value: packs.filter(pack => pack.id === watcher?.interiors.socialAreas.livingRoom)[0].label
              }
            ]}
          />
          <Accordion
            label='Kitchen'
            content={[
              {
                label: 'Theme',
                value: packs.filter(pack => pack.id === watcher?.interiors.kitchen.kitchen.kitchen)[0].label
              },
              {
                label: 'Kitchen Appliances',
                value: appliances.filter(appliance => appliance.id === watcher?.interiors.kitchen.kitchen.appliance)[0]
                  .label
              }
            ]}
          />
          <Accordion label='Intimate Areas' content={getIntimateAreas()} />
          <Accordion label='Bathrooms' content={getBathrooms()} />
        </div>
      </div>
      <PriceCard></PriceCard>

      {isDialogOpen && (
        <Portal>
          <div ref={dialogRef}>
            <Dialog
              type={getTracking().dialog.type}
              id={getTracking().dialog.id}
              projectName={projectName}
              onClose={() => {
                setIsDialogOpen(!isDialogOpen);
                dispatchSetDialog({ open: false, type: '', id: '' });
              }}
              setCustomFormStep={setCustomFormStep}
              setProjectSubmittedPageOpen={setProjectSubmittedPageOpen}
            ></Dialog>
          </div>
        </Portal>
      )}
    </div>
  );
};

export default Review;
