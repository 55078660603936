import { SVG } from '@assets/svg';
import './Information.scss';

const Information_Title = ({ children }) => {
  return (
    <div className='information-row'>
      <span className='information_title'>{children}</span>
      <SVG icon={'action/book-open'} />
    </div>
  );
};

export default Information_Title;
