// components
import Map from '@components/image-container/map/Map';
import Slideshow from '@components/image-container/slideshow/Slideshow';
// hooks
import useRedux from '@hooks/useRedux';
// assets
import review1 from '@assets/img/review1.png';
import review2 from '@assets/img/review2.png';
import review3 from '@assets/img/review3.png';
import review4 from '@assets/img/review4.png';
import review5 from '@assets/img/review5.png';
import Photo from './photo/Photo';
import { isArray } from '@utils/utils';
import { isEmpty } from '@utils/functions';

/**
 * ImageContainer component that renders different types of content based on the current step ID.
 *
 * This component uses the `useRedux` hook to get the current step ID and images.
 * It conditionally renders a Map, Slideshow, or Photo component based on the current step ID.
 *
 * @component
 * @returns {JSX.Element} The rendered component based on the current step ID.
 */
const ImageContainer = () => {
  const { getImages, getCurrentStepId } = useRedux();

  const currentStepId = getCurrentStepId();
  const svg = getImages().svg;
  const currentImage = getImages().current;

  const imagesReview = [review1, review2, review3, review4, review5];
  const fallbackImagePath = 'img/macroplotA.png';

  const toRender = () => {
    if (currentStepId === 'plot') return <Map svg={svg} />;
    if (currentStepId === 'review' && isArray(imagesReview) && !isEmpty(imagesReview))
      return <Slideshow className='slideshow' images={imagesReview} autoSlide />;
    if (currentStepId !== 'plot' && currentStepId !== 'review' && isArray(currentImage) && !isEmpty(currentImage))
      return <Slideshow className='slideshow' images={currentImage} />;
    if (currentStepId !== 'plot' && currentStepId !== 'review')
      return <Photo image={currentImage} fallbackImagePath={fallbackImagePath} />;
  };

  return <>{toRender()}</>;
};

export default ImageContainer;
