import { createSlice } from '@reduxjs/toolkit';

const macroplotsSlice = createSlice({
  name: 'macroplots',
  initialState: {},
  reducers: {
    setMacroplots: (state, action) => {
      return [...action.payload];
    }
  }
});

const macroplotsAction = macroplotsSlice.actions;
export { macroplotsSlice, macroplotsAction };
