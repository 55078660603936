import useRedux from './useRedux';
import SHA1 from 'crypto-js/sha1';
import { getUrl } from '@utils/images';
import { getImageUrl } from '@services/images';
import { isEmpty } from '@utils/functions';

const useImage = () => {
  // IMPROVE
  const { getCurrentStepId, getImages, dispatchImage, getCurrentQuestionId, getHouses } = useRedux();

  const getParking = watcher => {
    if (watcher?.exteriors?.parking?.type && watcher?.exteriors?.parking?.parkingSpace) {
      return `${watcher?.exteriors?.parking?.type}-${watcher?.exteriors?.parking?.parkingSpace}`;
    } else {
      return undefined;
    }
  };

  const newObject = (keysToExtract, originalObject) => {
    return keysToExtract.reduce((newObj, key) => {
      if (originalObject[key] !== undefined) {
        newObj[key] = originalObject[key];
      }
      return newObj;
    }, {});
  };

  const setImageOnRedux = async obj => {
    const hash = SHA1(JSON.stringify(obj)).toString();
    if (!getImages().hasOwnProperty(hash)) {
      // if image is not stored on redux, get Url for the proper combination, store on redux and return
      const queryURL = getUrl(obj);
      const imageUrlResponse = await getImageUrl({ queryURL });
      // update redux if imageUrlResponse exists
      if (imageUrlResponse && imageUrlResponse.url) dispatchImage({ hash, imageUrl: imageUrlResponse.url });
    } else {
      dispatchImage({ hash });
    }
  };

  const setImageContainerImage = async watcher => {
    const questionId = getCurrentQuestionId();
    if (questionId != null) {
      // dispatchUpdateCurrentQuestionId(questionId);
      if (getCurrentStepId() === 'layout' && !isEmpty(watcher['layout'])) {
        const layoutObj = {
          nrRooms: watcher?.['layout']?.['nrRooms'],
          houseType: watcher?.['layout']?.['houseType'],
          houseLayout: watcher?.['layout']?.['houseLayout']
        };
        // if (questionId === 'nrRooms') {
        //   const houseType = newObject(['nrRooms'], layoutObj);
        //   await setImageOnRedux(houseType);
        // }

        if (questionId === 'nrRooms' || questionId === 'houseType') {
          const houseType = newObject(['houseType'], layoutObj);
          await setImageOnRedux(houseType);
        }
        if (questionId === 'houseLayout') {
          const houseLayout = newObject(['houseLayout'], layoutObj);
          const hash = SHA1(JSON.stringify(houseLayout)).toString();
          dispatchImage({
            hash,
            imageUrl: getHouses().find(house => house.id === houseLayout.houseLayout).floorPlan
          });
        }
      }
      if (getCurrentStepId() === 'exteriors') {
        console.log(getCurrentStepId(), questionId);
        const exteriorsObj = {
          pool: watcher?.exteriors?.pool?.poolSize,
          parking: watcher?.exteriors?.parking?.parkingType
        };
        console.log(exteriorsObj);
        if (questionId === 'pool') {
          const pool = newObject(['pool'], exteriorsObj);
          await setImageOnRedux(pool);
        }
        if (questionId === 'parking') {
          const houseType = watcher?.layout?.houseType;
          const parking = newObject(['houseType', 'parking'], { ...exteriorsObj, houseType });
          await setImageOnRedux(parking);
        }
      }
      if (getCurrentStepId() === 'interiors' && !isEmpty(watcher['interiors'])) {
        dispatchImage({
          hash: { ...watcher['interiors'] },
          imageUrl:
            'https://bucketeer-464e2ef3-3fff-47e6-95be-b19199ee8512.s3.eu-west-1.amazonaws.com/Sala_73269ae0f4.png'
        });
      }
    }
  };

  const setImageContainerImageOld = async watcher => {
    if (getCurrentStepId() === 'plot' || getCurrentStepId() === 'layout') {
      const myWatcher = {
        ...watcher['plot'],
        houseType: watcher?.layout?.houseType,
        houseLayout: watcher?.layout?.houseLayout
      };

      if (!isEmpty(myWatcher)) {
        const hash = SHA1(JSON.stringify(myWatcher)).toString();

        if (watcher?.layout?.houseLayout && !isEmpty(watcher?.layout?.houseLayout)) {
          dispatchImage({
            hash,
            imageUrl:
              'https://bucketeer-464e2ef3-3fff-47e6-95be-b19199ee8512.s3.eu-west-1.amazonaws.com/Layout1_4040cab6c4.jpg'
          });
          return;
        }
        if (!getImages().hasOwnProperty(hash)) {
          // if image is not stored on redux, get Url for the proper combination, store on redux and return
          const queryURL = getUrl(myWatcher);
          console.log('queryURL', queryURL);
          const imageUrlResponse = await getImageUrl({ queryURL });
          // update redux if imageUrlResponse exists
          if (imageUrlResponse && imageUrlResponse.url) dispatchImage({ hash, imageUrl: imageUrlResponse.url });
        } else {
          dispatchImage({ hash });
        }
      }
    } else if (getCurrentStepId() === 'exteriors') {
      const myWatcher = {
        ...watcher['plot'],
        ...watcher['layout'],
        ...watcher['exteriors']
      };
      const hash = SHA1(JSON.stringify(myWatcher)).toString();
      dispatchImage({
        hash,
        imageUrl:
          'https://bucketeer-464e2ef3-3fff-47e6-95be-b19199ee8512.s3.eu-west-1.amazonaws.com/Parking_67efd3f011.jpg'
      });
    }
  };

  return { setImageContainerImage };
};

export default useImage;
