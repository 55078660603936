import LinkButton from '@components/ui/Buttons/LinkButton/LinkButton';
import Cell from '@components/ui/Cells/Cell';
import Material from '@components/ui/Material/Material';
// hooks
import useRedux from '@hooks/useRedux';
import useRender from '@hooks/useRender';

const SectionMaterial = ({ field, type, division, controllerId, onClickFn }) => {
  const { getPacks } = useRedux();
  const { isElementSelected, getWatcherName } = useRender();

  const isSelected = (controllerId, item) => {
    return isElementSelected({ controllerId, item });
  };

  const getLabel = (selectedPack, divisionPacksDetails) => {
    if (selectedPack) {
      return divisionPacksDetails.find(pack => pack.id === selectedPack).label;
    }
  };

  const getById = {
    'social-areas': () => {
      const divisionPacksIds = division.packs;
      const divisionPacksDetails = getPacks().filter(pack => divisionPacksIds.includes(pack.id));
      const packName = getLabel(getWatcherName(controllerId), divisionPacksDetails);

      return (
        <>
          <div className='packsSection'>
            {divisionPacksDetails.map(pack => (
              <Material field={field} imgUrl={pack.image} id={pack.id} controllerId={controllerId} item={pack} />
            ))}
          </div>
          <div className='spaceBetweenRow'>
            <span className='packLabel'>{packName}</span>
            <LinkButton text='Theme Details' onClickFn={() => onClickFn(packName, divisionPacksDetails)} />
          </div>
        </>
      );
    },
    kitchen: () => {
      const { id } = division;
      if (id === 'kitchen') {
        const divisionPacksIds = division.packs;
        const divisionPacksDetails = getPacks().filter(pack => divisionPacksIds.includes(pack.id));
        const packName = getLabel(getWatcherName(controllerId), divisionPacksDetails);

        return (
          <>
            <div className='packsSection'>
              {divisionPacksDetails.map(pack => (
                <Material field={field} imgUrl={pack.image} id={pack.id} controllerId={controllerId} item={pack} />
              ))}
            </div>
            <div className='spaceBetweenRow'>
              <span className='packLabel'>{packName}</span>
              <LinkButton text='Theme Details' onClickFn={() => onClickFn(packName, divisionPacksDetails)} />
            </div>
          </>
        );
      }
      if (id === 'appliance') {
        const appliances = division.appliances;

        return (
          <div className='row'>
            {appliances.map(appliance => (
              <Cell.Root field={field} item={appliance} isSelected={isSelected(controllerId, appliance)}>
                <Cell.Image imgUrl={appliance.logo} />
              </Cell.Root>
            ))}
          </div>
        );
      }
    },
    'intimate-areas': () => {
      const { packs } = division;
      const packsDetails = getPacks().filter(pack => packs.includes(pack.id));
      const packName = getLabel(getWatcherName(controllerId), packsDetails);
      return (
        <>
          <div className='packsSection'>
            {packsDetails.map(pack => (
              <Material field={field} imgUrl={pack.image} id={pack.id} controllerId={controllerId} item={pack} />
            ))}
          </div>
          <div className='spaceBetweenRow'>
            <span className='packLabel'>{packName}</span>
            <LinkButton text='Theme Details' onClickFn={() => onClickFn(packName, packsDetails)} />
          </div>
        </>
      );
    },
    poolCovering: () => {
      const { packs } = division;
      const packName = getLabel(getWatcherName(controllerId), packs);

      return (
        <>
          <div className='packsSection'>
            {packs.map(pack => (
              <Material field={field} imgUrl={pack.image} id={pack.id} controllerId={controllerId} item={pack} />
            ))}
          </div>
          <div className='spaceBetweenRow'>
            <span className='packLabel'>{packName}</span>
          </div>
        </>
      );
    }
  };

  return getById[type]();
};

export default SectionMaterial;
