import React, { useEffect, useState } from 'react';
//components
import Header from '@components/ui/Header/Header';
import TextInput from '@components/ui/Inputs/TextInput/TextInput';
import Button from '@components/ui/Buttons/Button/Button';
import { SVG } from '@assets/svg';
//styles
import './client.scss';
//hooks
import { useNavigate } from 'react-router-dom';
import image from './image.png';
import useRedux from '@hooks/useRedux';
//services
import { createProject } from '@services/projects';
import { getClientDetails } from '@services/client';
import Cookies from 'js-cookie';
//translations
import { useTranslation } from 'react-i18next';
//utils
import { isEmpty, isValidEmail } from '@utils/functions';

const ClientIdPage = ({}) => {
  const navigate = useNavigate();
  const { dispatchProjectInfo } = useRedux();
  const [clientEmail, setClientEmail] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const { t } = useTranslation();
  const sales_manager = Cookies.get('salesManager');
  const sales_manager_token = Cookies.get('sessionId');

  const validateEmail = async () => {
    if (!clientEmail) {
      setErrorMessage(t('client_id.empty_email'));
      return;
    }

    if (!isValidEmail(clientEmail)) {
      setErrorMessage(t('client_id.invalid_email'));
      return;
    }

    //TODO validate email on salesforce
    const projectBody = {
      project: 'TCT',
      sales_manager,
      client: clientEmail,
      status: 'draft',
      form: {}
    };

    try {
      const clientSFInfo = await getClientDetails(clientEmail, sales_manager_token);

      if (clientSFInfo?.data?.records && !isEmpty(clientSFInfo.data.records)) {
        try {
          const response = await createProject(projectBody);

          if (response) {
            console.log('Project saved to DB', response);
            dispatchProjectInfo(response);
            navigate(`/journey/${response.id}`);
          }
        } catch (error) {
          console.error('Error saving project to DB', error);
        }
      } else {
        console.log('Client does not exist');
        navigate('/client/register', { state: clientEmail });
      }
    } catch (error) {
      console.error('Error getting client details', error);
    }
  };

  return (
    <div className='client-container'>
      <Header shouldDisplay isDark />
      <div className='client-wrapper'>
        <img src={image} className='background-image' />
        <div className='content-wrapper'>
          <div
            className='back-button-wrapper'
            onClick={() => {
              navigate('/');
            }}
          >
            <SVG icon='navigation/arrow-left' customCSS={'back-icon'} />
            <button className='back-button'>{t('client_id.back')}</button>
          </div>
          <div className='content-section'>
            <div className='title-container'>
              <span className='main-title'>{t('client_id.main_title')}</span>
              <span className='sub-title'>{t('client_id.sub_title')}</span>
            </div>
            <TextInput
              icon={'action/email'}
              placeholder=''
              type='email'
              name='email'
              id='email'
              label={t('client_id.email')}
              customCSS={{ height: '44px' }}
              onChangeFn={e => {
                setClientEmail(e.target.value);
                setErrorMessage(null);
              }}
              errorMessage={errorMessage}
              onEnterPress={() => {
                validateEmail();
              }}
            />
            <div className='button-container'>
              <Button primary text={t('client_id.button')} onClickFn={validateEmail} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientIdPage;
