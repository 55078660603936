import { plotsAction } from './plots-slice';
import { getPlots } from '../../services/plots';

export const setPlots = async dispatch => {
  try {
    const response = await getPlots();
    dispatch(plotsAction.setPlots(response));
  } catch (error) {
    console.log('ERROR');
  } finally {
  }
};
