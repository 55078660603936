import { SVG } from '@assets/svg';
import './FilterButton.scss';

const FilterButton = ({ filtersCount, onClickFn }) => {
  return (
    <div className={`filter-button${filtersCount > 0 ? ' active' : ''}`} onClick={onClickFn}>
      <SVG icon={'action/filter'} />
      <p>Filters and sorting</p>
      {filtersCount > 0 && (
        <div className='active'>
          <p>{filtersCount}</p>
        </div>
      )}
    </div>
  );
};

export default FilterButton;
