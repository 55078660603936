import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// hooks
import useRedux from '@hooks/useRedux';
// components
import AdditionalServicesCard from '@components/ui/Cards/AdditionalServicesCard/AdditionalServicesCard';
import Button from '@components/ui/Buttons/Button/Button';
import MultiChoiceModal from '@components/ui/Overlays/Modals/MultiChoiceModal/MultiChoiceModal';
import Portal from '@components/ui/Overlays/Portal/Portal';
import Slideshow from '@components/image-container/slideshow/Slideshow';
// styles
import './ProjectSubmitted.scss';
// utils
import { ACTIONS } from '@utils/enums';
import Header from '@components/ui/Header/Header';
import review1 from '@assets/img/review1.png';
import review2 from '@assets/img/review2.png';
import review3 from '@assets/img/review3.png';
import review4 from '@assets/img/review4.png';
import review5 from '@assets/img/review5.png';

const ProjectSubmitted = ({ setCustomFormStep, setProjectSubmittedPageOpen }) => {
  const {
    getProjectSubmittedContent,
    dispatchResetForm,
    dispatchProjectInfo,
    getCurrentProject,
    FORMSTATE,
    dispatchResetFilters
  } = useRedux();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef(null);
  const content = getProjectSubmittedContent()[0];
  const imagesReview = [review1, review2, review3, review4, review5];

  const navigate = useNavigate();

  const goToLogin = () => {
    navigate('/sales-manager/login');
  };

  const handleClick = async action => {
    if (action === ACTIONS.Exit) {
      FORMSTATE.reset();
      dispatchResetFilters();
      navigate('/dashboard');
    }
    return;
  };

  useEffect(() => {
    const handleEscKey = event => {
      if (event.key === 'Escape') {
        setIsModalOpen(!setIsModalOpen);
      }
    };
    if (isModalOpen) window.addEventListener('keydown', handleEscKey);
    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [isModalOpen]);

  // Close modal on click outside the modal
  useEffect(() => {
    const handleClickOutside = event => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalOpen(!setIsModalOpen);
      }
    };
    if (isModalOpen) window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalOpen]);

  return (
    <div className='confirm-interest-container'>
      <Header shouldDisplay isDark />
      <div className='confirm-interest-wrapper'>
        <div className='confirm-interest-wrapper-image'>
          <Slideshow images={imagesReview} autoSlide></Slideshow>
        </div>
        <div className='confirm-interest-content'>
          <div className='confirm-interest-content-text'>
            <div className='confirm-interest-content-text-header'>
              <span className='id'>{getCurrentProject().id}</span>
              <div className='confirm-interest-content-text-header-content'>
                <span className='title'>{content.title.replace('{{projectName}}', 'Comporta 001')}</span>{' '}
                {/* FIX project name */}
                <span className='subtitle'>{content.description}</span>
              </div>
              <AdditionalServicesCard
                card={content.card}
                onClickFn={() => {
                  setIsModalOpen(!isModalOpen);
                }}
              ></AdditionalServicesCard>
            </div>
            <div className='confirm-interest-content-text-footer'>
              {content.actions.map(action => {
                const type = action.type.toLowerCase();
                if (type === 'primary') {
                  return (
                    <Button
                      primary
                      text={action.label}
                      onClickFn={() => {
                        handleClick(action.action);
                      }}
                    ></Button>
                  );
                } else if (type === 'secondary') {
                  return (
                    <Button
                      secondary
                      text={action.label}
                      onClickFn={() => {
                        handleClick(action.action);
                      }}
                    ></Button>
                  );
                } else if (type === 'ghost') {
                  return (
                    <Button
                      ghost
                      text={action.label}
                      onClickFn={() => {
                        handleClick(action.action);
                      }}
                    ></Button>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <Portal>
          <div ref={modalRef}>
            <MultiChoiceModal
              onClose={() => {
                setIsModalOpen(!isModalOpen);
              }}
            ></MultiChoiceModal>
          </div>
        </Portal>
      )}
    </div>
  );
};

export default ProjectSubmitted;
