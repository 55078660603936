import { createSlice } from '@reduxjs/toolkit';
import { timer } from 'd3';

const INITIAL_STATE = {
  visibility: false,
  timer: 5000,
  onClose: null
};
const notificationSlice = createSlice({
  name: 'notification',
  initialState: INITIAL_STATE,
  reducers: {
    setNotification: (state, action) => {
      state.visibility = true;
      if (action.payload.timer === false) state.timer = null;
      state.type = action.payload.type;
      state.length = action.payload.length;
      state.status = action.payload.status;
      state.action = action.payload.action;
      state.title = action.payload.title;
      state.description = action.payload.description;
    },
    reset: () => {
      return INITIAL_STATE;
    }
  }
});

const notificationAction = notificationSlice.actions;
export { notificationSlice, notificationAction };
