import './InlineMessage.scss';
import { SVG } from '@assets/svg';

const InlineMessage = ({ type, message }) => {
  return (
    <div className={`inline-message ${type}`}>
      {type === 'info' && <SVG icon={'semantic/info-outline'} />}
      {type === 'error' && <SVG icon={'semantic/octagon-x-outline'} />}
      <p>{message}</p>
    </div>
  );
};

export default InlineMessage;
