import { Cell as Cell_Root } from './Cell';
import { Cell_Image } from './Cell_Image';
import Cell_Text from './Cell_Text';

const Cell = {
  Root: Cell_Root,
  Text: Cell_Text,
  Image: Cell_Image
};

export default Cell;
