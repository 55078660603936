import { packsAction } from './packs-slice';
import { getPacks } from '@services/packs';

export const setPacks = async dispatch => {
  try {
    const response = await getPacks();
    dispatch(packsAction.setPacks(response));
  } catch (error) {
    console.log('ERROR');
  } finally {
  }
};
