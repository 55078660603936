import { SERVICES_POINTS, ENDPOINTS } from './constants';

const getFullEndpoint = ({ microservice, service, queryParams }) => {
  let url = service
    ? `${ENDPOINTS[microservice.toUpperCase()]}/${SERVICES_POINTS[microservice.toUpperCase()][service]}`
    : `${ENDPOINTS[microservice.toUpperCase()]}`;

  if (queryParams) {
    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');
    url += `?${queryString}`;
  }

  return url;
};

export { getFullEndpoint };
