import { useState, useEffect } from 'react';
//components
import Header from '@components/ui/Header/Header';
//styles
import './infoPages.scss';

const InfoPageComponent = ({ data }) => {
  const [imageIsReady, setImageIsReady] = useState(false);

  return (
    <>
      <div className='info-container'>
        <Header shouldDisplay />
        <div className='image-wrapper'>
          <img
            onLoad={() => {
              setImageIsReady(true);
            }}
            className='background-image'
            src={data?.pageImage}
            alt={data?.pageTitle || 'Background image'}
          />
          <span className='page-title'>{data?.pageTitle}</span>
        </div>

        {imageIsReady && (
          <div className='text-wrapper'>
            <span dangerouslySetInnerHTML={{ __html: data?.bodycopy }} />
          </div>
        )}
      </div>
    </>
  );
};

export default InfoPageComponent;
