//hooks
import React, { useEffect, useState } from 'react';
//styling
import './homepage.scss';
//utils
import { fetchVideo } from '@utils/cacheUtils/videoCache.util';

const VideoComponent = ({ videoUrl, setVideoIsReady }) => {
  const [videoSrc, setVideoSrc] = useState(null);

  const handleVideoIsReady = () => {
    setVideoIsReady(true);
  };

  useEffect(() => {
    const loadVideo = async () => {
      try {
        const src = await fetchVideo('homepageVideo', videoUrl);
        setVideoSrc(src);
      } catch (error) {
        console.error('Erro ao carregar o vídeo:', error);
      }
    };
    loadVideo();
  }, [videoUrl]);

    //será necessário revogar a blob do vídeo?
    //   useEffect(() => {
    //     return () => {
    //         if (videoSrc) {
    //             URL.revokeObjectURL(videoSrc);
    //         }
    //     };
    // }, [videoSrc]);

  return (
    <video autoPlay loop muted onCanPlay={handleVideoIsReady} src={videoSrc} className='background-video'/>
  );
};

export default VideoComponent;
