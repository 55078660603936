export const isObject = element => typeof element === 'object' && !Array.isArray(element) && element !== null;

export const isArray = element => Array.isArray(element);

export const isObjectsEquals = (x, y) => {
  const ok = Object.keys,
    tx = typeof x,
    ty = typeof y;
  return x && y && tx === 'object' && tx === ty
    ? ok(x).length === ok(y).length && ok(x).every(key => isObjectsEquals(x[key], y[key]))
    : x === y;
};

export const flattenObject = obj => {
  const result = {};

  // Iterate over the outer keys
  for (let outerKey in obj) {
    if (obj.hasOwnProperty(outerKey)) {
      // Get the inner object
      const innerObj = obj[outerKey];

      // Iterate over the inner keys and move them to the result object
      for (let innerKey in innerObj) {
        if (innerObj.hasOwnProperty(innerKey)) {
          result[innerKey] = innerObj[innerKey];
        }
      }
    }
  }

  return result;
};

export const isObjectValuesNullish = obj => {
  return Object.values(obj).every(s => s === null || s === undefined);
};
