import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: [],
  reducers: {
    setAuth: (state, action) => {
      action.payload.forEach((newEntry) => {
        const key = Object.keys(newEntry)[0];
        const existingIndex = state.findIndex((item) =>
          Object.prototype.hasOwnProperty.call(item, key)
        );

        if (existingIndex !== -1) {
          state[existingIndex] = newEntry;
        } else {
          state.push(newEntry);
        }
      });
    },
  },
});


const authAction = authSlice.actions;
export { authSlice, authAction };
