import { partnersAction } from './partners-slice';
import { getPartners } from '@services/partners';

export const setPartners = async dispatch => {
  try {
    const response = await getPartners();
    dispatch(partnersAction.setPartners(response));
  } catch (error) {
    console.log('ERROR');
  } finally {
  }
};
