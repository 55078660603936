import { getHomepageContent } from '@services/homepage';
import { homepageAction } from './homepage-slice';

export const setHomepageContent = async dispatch => {
  try {
    const response = await getHomepageContent();
    dispatch(homepageAction.setHomepageContent([response]));
  } catch (error) {
    console.log('ERROR', error);
  } finally {
  }
};
