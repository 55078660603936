import Information from '@components/ui/Information';

const InformationBlock = ({ information, onClickFn }) => {
  return (
    <Information.Root modal={information.modal} onClickFn={onClickFn}>
      <Information.Title>{information.title}</Information.Title>
      <Information.Description>{information.description}</Information.Description>
    </Information.Root>
  );
};

export default InformationBlock;
