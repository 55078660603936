import '../../shared.scss';
import { MaterialController } from '@components/answers/controllers/MaterialController';
import SectionMaterial from '@components/answers/SectionMaterial';
import useRedux from '@hooks/useRedux';

export const SocialAreas = ({ control, divisions, question, onClickFn }) => {
  const { getCurrentStepId } = useRedux();
  return (
    <div className='materialContainer'>
      {divisions.map((division, index) => (
        <div key={index} className='materialWrapper'>
          <span className='title'>{division.title}</span>
          <MaterialController
            control={control}
            currentStepId={getCurrentStepId()}
            division={division}
            question={question}
            onClickFn={onClickFn}
            Component={SectionMaterial}
          />
        </div>
      ))}
    </div>
  );
};
