import axios from 'axios';
import { getFullEndpoint } from '../utils/endpoints/functions';

export const createProject = async body => {
  const HEADERS = {
    accept: '*/*',
    'Content-Type': 'application/json'
  };

  const requestObj = {
    method: 'post',
    url: `${getFullEndpoint({ microservice: 'project' })}/create`,
    headers: HEADERS,
    data: body
  };

  const response = await axios.request(requestObj).catch(e => console.log(e));

  return response?.data;
};

export const getProjectById = async projectId => {
  const HEADERS = {
    accept: '*/*'
  };

  const requestObj = {
    method: 'get',
    url: `${getFullEndpoint({ microservice: 'project' })}/getProject/${projectId}`,
    headers: HEADERS
  };

  const response = await axios.request(requestObj).catch(e => console.log(e));

  return response?.data;
};
