import Cell from '@components/ui/Cells/Cell';
import useRender from '@hooks/useRender';

const TextOnly = ({ field, item, renderCondition, controllerId }) => {
  const isSelected = useRender().isElementSelected({ controllerId, item });

  return (
    <Cell.Root field={field} item={item} renderCondition={renderCondition} isSelected={isSelected}>
      <Cell.Text isSelected={isSelected}>{item.label}</Cell.Text>
    </Cell.Root>
  );
};

export default TextOnly;
