import './DetailedCell.scss';
import useRender from '@hooks/useRender';

const DetailedCell = ({
  field,
  item,
  renderCondition,
  shouldTriggerRenderCondition = true,
  isSelected,
  customFn,
  children
}) => {
  const { itemShouldRender } = useRender();

  const handleClick = () => {
    field.onChange(item.id);
    if (customFn) customFn();
  };

  // The component will only render if either:
  // shouldTriggerRenderCondition is false, or
  // itemShouldRender(renderCondition, item) returns true.
  const shouldRender = !shouldTriggerRenderCondition || itemShouldRender(renderCondition, item);

  if (!shouldRender) return null;

  return (
    <div className={`detailed-cell ${isSelected ? 'selected' : ''}`} onClick={handleClick}>
      {children}
    </div>
  );
};

export { DetailedCell };
