export const PLOT_STATUS = Object.freeze({
  Sold: 'sold',
  Available: 'available',
  InNegotiation: 'in-negotiation'
});

export const DIALOG_TYPE = Object.freeze({
  ConfirmInterest: 'confirm-interest',
  SaveProject: 'save-project',
  ConsultPrice: 'consult-price',
  SaveExit: 'save-exit'
});

export const ACTIONS = Object.freeze({
  NewDesign: 'NEW-DESIGN',
  Exit: 'EXIT',
  Back: 'BACK',
  ConfirmInterest: 'CONFIRM-INTEREST'
});
