import React from 'react';
import { useState, useCallback, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import debounce from 'lodash.debounce';
// components
import RenderAnswers from '@components/answers/RenderAnswers';
import FilterButton from '@components/ui/Buttons/FilterButton/FilterButton';
import LinkButton from '@components/ui/Buttons/LinkButton/LinkButton';
import Portal from '@components/ui/Overlays/Portal/Portal';
import BasicModal from '@components/ui/Overlays/Modals/BasicModal/BasicModal';
import Dialog from '@components/ui/Overlays/Dialog/Dialog';
import InlineMessage from '@components/ui/Notification/InlineMessage';
// hooks
import { useFormContext } from 'react-hook-form';
import useRedux from '@hooks/useRedux';
import useDefaultValues from '@hooks/useDefaultValues';
import useFilters from '@hooks/useFilters';
import useImage from '@hooks/useImage';
// styles
import './journey.scss';
import 'swiper/css';
import 'swiper/css/pagination';
// utils
import { PLOTS_LOAD_MORE } from 'config';
import { getInitialNumberAnswers, getNrQuestionToDisplay } from '@utils/form/functions';
import { isEmpty } from '@utils/functions';
import { clearPrevent, isToPrevent, setPrevent } from '@utils/preventions';

const Journey = ({ currentStep, control, currentStepId, currentStepNr, setShowFiltersPanel, inlineMessage }) => {
  const { questions } = currentStep;
  const {
    getTracking,
    getForms,
    dispatchResetForm,
    getFilters,
    getModals,
    FORMSTATE,
    dispatchSetDialog,
    dispatchResetNotification,
    dispatchNotification,
    dispatchUpdateCurrentQuestionId
  } = useRedux();
  const {
    getMacroplotByPlotId,
    getPlotsByMacroplotId,
    getPlotsFiltered,
    getModalsByHouseType,
    getModalsByDivision,
    getHouseTypeByPlotAndTypology
  } = useFilters();
  const { setDefaultValues, setDefaultValuesAfterChange } = useDefaultValues();
  const { setImageContainerImage } = useImage();

  const { watch, reset, getValues, setValue } = useFormContext(); // Access the shared form instance via useFormContext
  const watcher = watch();
  const selectedValues = getValues();
  const FORM_STEPS_NAMES = getForms().journey?.map(form => form.title);

  const [isPortalOpen, setIsPortalOpen] = useState(false);
  const modalRef = useRef(null);
  const [isDialogOpen, setIsDialogOpen] = useState(getTracking().dialog.open);
  const [packsDetails, setPacksDetails] = useState(null);
  const [division, setDivision] = useState(null);

  // track number of answers to display (excepcional case to plots)
  const [nrAnswersToDisplay, setNrAnswersToDisplay] = useState(getInitialNumberAnswers({ questions }));

  const [filtersCount, setFiltersCount] = useState(Object.keys(getFilters()).length);

  const [showInlineMessageMacroplot, setShowInlineMessageMacroplot] = useState(false);
  const [showInlineMessagePlot, setShowInlineMessagePlot] = useState(false);

  // used to scroll to the first inline message
  const inlineMessageMacroplotRef = useRef(null);
  const inlineMessagePlotRef = useRef(null);

  // tracking changes
  const [lastMacroplot, setLastMacroplot] = useState(FORMSTATE.get('plot')?.macroplot ?? null); // Tracks the previous macroplot

  const lastNrRooms = getTracking().nrRooms;
  const lastHouseType = getTracking().houseType;
  const selectedMacroplot = watch('plot.macroplot');
  const selectedPlot = watch('plot.plot');
  const selectedNrRooms = watch('layout.nrRooms');
  const selectedHouseType = watch('layout.houseType');

  const [visibilityMap, setVisibilityMap] = useState({});
  const [fadeClass, setFadeClass] = useState('fade-in'); // State for managing fade effect
  const [currentQuestion, setCurrentQuestion] = useState({
    id: null
  });

  // useEffect to track filters counter
  useEffect(() => {
    const filters = getFilters();
    let count = Object.keys(filters).length;
    count += filters?.bedrooms?.length - 1 || 0;
    count += filters?.houseType?.length - 1 || 0;
    if (filters?.plotMinArea === 'Min') count -= 1;
    if (filters?.plotMaxArea === 'Max') count -= 1;
    if (filters?.sortBy) count -= 1;
    setFiltersCount(count);
  }, [getFilters]);

  // Step 1 - If macroplot changes, reset all form
  useEffect(() => {
    if (getTracking().reset.state || currentStepNr !== 0) return;
    // Exit if plot was selected first, without macroplot selection
    if (isToPrevent('plot')) {
      clearPrevent('plot');
      return;
    }

    // No macroplot selected or same macroplot as before
    if (!selectedMacroplot || selectedMacroplot === lastMacroplot) return;

    // Update the last macroplot and reset the form
    setLastMacroplot(selectedMacroplot);
    dispatchResetForm({
      reset: true,
      trigger: 'RESET_ALL_MACROPLOT',
      after: { plot: { macroplot: selectedMacroplot, plot: '' } }
    });
  }, [selectedMacroplot, lastMacroplot, reset]);

  // Step 1 - Associate a macroplot, when a plot is picked from all plot list
  useEffect(() => {
    if (getTracking().reset.state || currentStepNr !== 0) return;
    if (selectedPlot && !selectedMacroplot) {
      console.log('useEffect - plot selects macroplots');
      // setPreventTriggerPlots(true);
      setPrevent('plot');
      const associatedMacroplot = getMacroplotByPlotId(selectedPlot).id;
      selectedValues.plot.macroplot = associatedMacroplot;
      setValue('plot.macroplot', selectedValues.plot.macroplot);
      setLastMacroplot(associatedMacroplot);
    }
  }, [selectedPlot, selectedMacroplot, setValue]);

  // Step 2 - If nrRooms changes, set a new default values
  useEffect(() => {
    console.log('useEffect step 1');
    if (currentStepNr !== 1) return;
    // Check if 'lastNrRooms' is null or undefined, indicating the first render
    if (lastNrRooms == null) {
      setPrevent('houseType');
      setDefaultValues(currentStepNr, currentStepId);
      return;
    }

    // Exit if the selected value hasn't changed
    if (selectedNrRooms === lastNrRooms) return;

    console.log('useEffect triggered by nrRooms');
    // dispatchUpdateTracking({ idToUpdate: 'nrRooms', updatedObject: selectedNrRooms }); // Update `lastNrRooms` to the new value

    // Trigger function when the value changes
    setDefaultValuesAfterChange(currentStepNr, {
      id: 'nrRooms',
      newValue: selectedValues.layout.nrRooms
    });
    dispatchResetForm({
      reset: true,
      trigger: 'RESET_EXTERIORS_INTERIORS',
      after: { ...getValues(), exteriors: null, interiors: null }
    });
  }, [selectedNrRooms, lastNrRooms]);

  // Step 2 - If houseType changes, set a new default values
  useEffect(() => {
    if (currentStepNr !== 1) return;
    if (isToPrevent('houseType')) {
      clearPrevent('houseType');
      return;
    }

    // Exit if same houseType as before
    if (selectedHouseType === lastHouseType) return;

    // dispatchUpdateTracking({ idToUpdate: 'houseType', updatedObject: selectedHouseType });

    // Trigger function when the value changes
    setDefaultValuesAfterChange(currentStepNr, {
      id: 'houseType',
      newValue: selectedHouseType
    });
  }, [selectedHouseType, lastHouseType]);

  // Step 3 - If poolSize is not none, set new default values
  useEffect(() => {
    if (getValues()?.exteriors && !isEmpty(getValues()?.exteriors)) {
      const { poolSize, poolCovering, poolHeated } = selectedValues?.exteriors?.pool;
      if (poolSize !== 'none' && poolCovering === null && poolHeated === null) {
        setDefaultValues(currentStepNr, currentStepId);
      }
    }
  }, [getValues()?.exteriors?.pool?.poolSize]);

  // useEffect - Set default values on every step change, if currentStep watcher is not setted
  useEffect(() => {
    console.log('auto pre-select');
    if (!watcher[currentStepId] || isEmpty(watcher[currentStepId])) {
      console.log('useEffect, auto pre-select');
      setDefaultValues(currentStepNr, currentStepId);
    }
  }, [currentStepNr]);

  const togglePortal = () => {
    setIsPortalOpen(!isPortalOpen);
  };

  useEffect(() => {
    if (getTracking()?.dialog?.open) {
      setIsDialogOpen(true);
    }
  }, [getTracking().dialog]);

  // Close modal on 'esc' key press
  useEffect(() => {
    const handleEscKey = event => {
      if (event.key === 'Escape') {
        if (isPortalOpen) togglePortal();
        if (isDialogOpen) setIsDialogOpen(!isDialogOpen);
      }
    };
    if (isPortalOpen || isDialogOpen) window.addEventListener('keydown', handleEscKey);
    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [isPortalOpen, isDialogOpen]);

  // Close modal on click outside the modal
  useEffect(() => {
    const handleClickOutside = event => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        if (isPortalOpen) togglePortal();
        if (isDialogOpen) setIsDialogOpen(!isDialogOpen);
      }
    };
    if (isPortalOpen || isDialogOpen) window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isPortalOpen, isDialogOpen]);

  const getModalsFiltered = () => {
    let modals = [];
    if (currentStepNr === 1) {
      const houseTypes = getHouseTypeByPlotAndTypology(selectedValues.plot.plot, selectedValues.layout.nrRooms);
      modals = getModalsByHouseType(houseTypes, selectedValues.layout.houseType);
    } else if (currentStepNr === 2) {
      modals = getModals().filter(modal => modal.id === 'curated-selections')[0].content;
    } else if (currentStepNr === 3) {
      modals = getModalsByDivision(packsDetails, division);
    }
    return modals;
  };

  const onLoadMorePlots = questionIndex => {
    setNrAnswersToDisplay(counts =>
      counts.map((count, index) => (index === questionIndex ? count + PLOTS_LOAD_MORE : count))
    );
  };

  // show inlineMessage if it exists
  useEffect(() => {
    if (inlineMessage && !isEmpty(inlineMessage)) {
      if (!selectedMacroplot) setShowInlineMessageMacroplot(true);
      if (!selectedPlot) setShowInlineMessagePlot(true);
    }
  }, [inlineMessage]);

  // don't show inlineMessage if macroplot or plot is selected
  useEffect(() => {
    if (selectedMacroplot) setShowInlineMessageMacroplot(false);
    if (selectedPlot) setShowInlineMessagePlot(false);
  }, [selectedMacroplot, selectedPlot]);

  // if inlineMessage is not shown, reset notification
  useEffect(() => {
    if (selectedMacroplot && selectedPlot && !showInlineMessageMacroplot && !showInlineMessagePlot) {
      dispatchResetNotification();
    }
  }, [showInlineMessageMacroplot, showInlineMessagePlot]);

  // if inlineMessage is shown, scroll to the first inline message
  useEffect(() => {
    if (inlineMessageMacroplotRef.current && showInlineMessageMacroplot) {
      inlineMessageMacroplotRef.current.scrollIntoView({ behavior: 'smooth' });
    } else if (!showInlineMessageMacroplot && inlineMessagePlotRef.current && showInlineMessagePlot) {
      inlineMessagePlotRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showInlineMessageMacroplot, showInlineMessagePlot]);

  const QuestionItem = ({ question, answers, questionIndex, isPlotQuestion, onVisibleChange }) => {
    const { ref, entry } = useInView({
      threshold: Array.from({ length: 101 }, (_, i) => i / 100) // 0%, 1%, ..., 100% visibility
    });

    const prevRatio = useRef(0); // Track previous visibility ratio
    useEffect(() => {
      if (entry) {
        const visibilityRatio = entry.intersectionRatio || 0;

        // Only trigger onVisibleChange if the ratio changes meaningfully
        if (Math.abs(visibilityRatio - prevRatio.current) > 0.05) {
          // console.log('Visibility change:', question.text, visibilityRatio);
          prevRatio.current = visibilityRatio; // Update the tracked ratio
          onVisibleChange(question, visibilityRatio);
        }
      }
    }, [entry, question, onVisibleChange]);

    return (
      <div ref={ref} key={`${question}-${questionIndex}`} className='question-wrapper'>
        {/* TOP SECTION */}
        <div
          className='top-section'
          ref={question.id === 'macroplot' ? inlineMessageMacroplotRef : inlineMessagePlotRef}
        >
          <span className='headline'>{question.headline}</span>
          {question.description && <span className='description'>{question.description}</span>}
        </div>

        {question.type === 'show-plots' && (
          <div className='filters'>
            <FilterButton filtersCount={filtersCount} onClickFn={setShowFiltersPanel} />
          </div>
        )}

        {/* ANSWERS */}
        <div className='answers'>
          <RenderAnswers
            control={control}
            question={{ ...question, answers: answers }}
            currentStepId={currentStepId}
            onModalOpen={(division, divisionPacksDetails) => {
              setDivision(division);
              setPacksDetails(divisionPacksDetails);
              togglePortal();
            }}
          />
        </div>
        {question.type === 'show-macroplots' && (
          <div className='resetMacroplots'>
            <LinkButton
              icon='action/rotate-ccw'
              text='Clear selection'
              onClickFn={() => {
                dispatchResetForm({ reset: true, trigger: 'RESET_STEP_PLOT' });
              }}
            />
          </div>
        )}
        {isPlotQuestion && nrAnswersToDisplay[questionIndex] < question.answers[0].items.length && (
          <div className='showMorePlots'>
            <LinkButton text='Load More' onClickFn={() => onLoadMorePlots(questionIndex)} />
          </div>
        )}
        {showInlineMessageMacroplot && question.id === 'macroplot' && (
          <InlineMessage type={inlineMessage.type} message={inlineMessage.message} />
        )}
        {showInlineMessagePlot && question.id === 'plot' && (
          <InlineMessage type={inlineMessage.type} message={inlineMessage.message} />
        )}
      </div>
    );
  };

  const updateMostVisibleQuestion = useCallback(() => {
    debounce(() => {
      const mostVisibleEntry = Object.entries(visibilityMap)
        .filter(([_, ratio]) => ratio > 0) // Consider only visible items
        .sort((a, b) => b[1] - a[1])[0]; // Sort by visibility ratio (descending)

      if (mostVisibleEntry) {
        const [mostVisibleId] = mostVisibleEntry;
        // If mostVisibleId = undefined (question without ID - informations), set next question as mostVisibleQuestion, otherwise set the mostVisibleQuestion
        const mostVisibleQuestion =
          mostVisibleId === 'undefined'
            ? questions[questions.findIndex(question => question.component === 'information.information-block') + 1]
            : questions.find(question => question.id === mostVisibleId);

        if (mostVisibleQuestion && currentQuestion && mostVisibleQuestion.id !== currentQuestion.id) {
          // Trigger fade-out first
          setFadeClass('fade-out');

          // Wait for fade-out to complete before updating the question
          // setTimeout(() => {
          setCurrentQuestion(mostVisibleQuestion);
          // setImageContainerImage(watcher, mostVisibleQuestion.id);
          dispatchUpdateCurrentQuestionId(mostVisibleQuestion.id);
          setFadeClass('fade-in'); // Trigger fade-in after update
          // }, 300); // Matches the CSS transition duration
        }
      }
    }, 100)(); // Debounce to reduce rapid updates
  }, [visibilityMap, currentQuestion, questions]);

  const handleVisibilityChange = useCallback((question, visibilityRatio) => {
    setVisibilityMap(prev => {
      const prevRatio = prev[question.id] || 0;
      // Only update if visibility ratio changes significantly
      if (Math.abs(prevRatio - visibilityRatio) > 0.05) {
        return { ...prev, [question.id]: visibilityRatio };
      }
      return prev;
    });
  }, []);

  useEffect(() => {
    updateMostVisibleQuestion();
  }, [visibilityMap, updateMostVisibleQuestion]);

  // useEffect to reset visibilityMap when step changes
  useEffect(() => {
    setVisibilityMap({});
  }, [currentStepNr]);

  return (
    <div className='questions-container'>
      {/* Iterate on step.questions */}
      {questions?.map((question, index) => {
        // Determine how many answers to display
        const isPlotQuestion = question.id === 'plot';

        if (isPlotQuestion && watcher?.plot?.macroplot) {
          question = { ...question, answers: [{ items: getPlotsByMacroplotId(watcher?.plot?.macroplot) }] };
        }
        if (isPlotQuestion && !isEmpty(getFilters())) {
          // update plot answers if filters are applied
          const filters = getFilters();
          question = { ...question, answers: [{ items: getPlotsFiltered(filters, question.answers[0].items) }] };
        }
        const answers = getNrQuestionToDisplay({ isPlotQuestion, question, questionIndex: index, nrAnswersToDisplay });

        // const plotAnswers = watcher?.plot?.macroplot ?  : answersToDisplay
        return (
          <QuestionItem
            question={question}
            key={`${question.id}-${index}`}
            answers={answers}
            questionIndex={index}
            isPlotQuestion={isPlotQuestion}
            onVisibleChange={handleVisibilityChange}
          />
        );
      })}

      {/* RESET SELECTIONS */}
      {(currentStepId === 'exteriors' || currentStepId === 'interiors') && (
        <LinkButton
          icon='action/rotate-ccw'
          text='Reset selections to default'
          onClickFn={() => {
            dispatchNotification({
              type: 'toast',
              status: 'success',
              title: '{{section}} reset to default.'.replace('{{section}}', FORM_STEPS_NAMES[currentStepNr])
            });
            setDefaultValues(currentStepNr, currentStepId);
          }}
        />
      )}

      {/* <button onClick={togglePortal}>{isPortalOpen ? 'Close Portal' : 'Open Portal'}</button> */}
      {isPortalOpen && (
        <Portal>
          <div ref={modalRef}>
            <BasicModal currentStepNr={currentStepNr} modals={getModalsFiltered()} onClose={togglePortal}></BasicModal>
          </div>
        </Portal>
      )}
      {isDialogOpen && (
        <Portal>
          <div ref={modalRef}>
            <Dialog
              type={getTracking().dialog.type}
              id={getTracking().dialog.id}
              onClose={() => {
                setIsDialogOpen(!isDialogOpen);
                dispatchSetDialog({ open: false, type: '', id: '' });
              }}
            ></Dialog>
          </div>
        </Portal>
      )}
    </div>
  );
};

export default Journey;
