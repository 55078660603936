import React from 'react';
import PropTypes from 'prop-types';
import './IconButton.scss';
import { SVG } from '@assets/svg';

const IconButton = React.forwardRef(({ size, onImageBg, icon, children, onClickFn, ...props }, ref) => {
  // Determine the button classes based on the size and onImageBg props
  const IconButtonClass = `
    icon-button 
    ${size === 'small' ? 'icon-button_small' : 'icon-button_default'} 
    ${onImageBg ? 'icon-button_imageBg' : ''}
  `;

  return (
    <div className={IconButtonClass.trim()} onClick={onClickFn} {...props} ref={ref}>
      {/* If an icon is provided, render it */}
      {/* {icon && <img src={icon} alt="button-icon" className="button__icon" />} */}
      <SVG icon={icon} />
      {children}
    </div>
  );
});

// Define the default prop values
IconButton.defaultProps = {
  size: 'default',
  onImageBg: false,
  icon: null
};

// Define prop types for validation
IconButton.propTypes = {
  size: PropTypes.oneOf(['default', 'small']),
  onImageBg: PropTypes.bool,
  icon: PropTypes.string // Path to SVG or image
  // children: PropTypes.node.isRequired, // To accept children inside the button
};

export default IconButton;
