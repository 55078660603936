import { housesAction } from './houses-slice';
import { getHouses } from '../../services/houses';

export const setHouses = async dispatch => {
  try {
    const response = await getHouses();
    dispatch(housesAction.setHouses(response));
  } catch (error) {
    console.log('ERROR');
  } finally {
  }
};
