import { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import validator from 'validator';
// components
import Checkbox from '@components/ui/Checkbox/Checkbox';
import Button from '@components/ui/Buttons/Button/Button';
import TextInput from '@components/ui/Inputs/TextInput/TextInput';
import Dropdown from '@components/ui/Dropdown/Dropdown';
import RadioButton from '@components/ui/RadioButton/RadioButton';
import Header from '@components/ui/Header/Header';
import InputPhone from '@components/ui/Inputs/PhoneInput/InputPhone';
import LinkButton from '@components/ui/Buttons/LinkButton/LinkButton';
// hooks
import useRedux from '@hooks/useRedux';
// styles
import './ClientRegistration.scss';
//utils
import image from '../Client/image.png';
import { isEmpty, isValidEmail } from '@utils/functions';
import { NATIONALITY_OPTIONS, LANGUAGES_OPTIONS, PARTNERS_OPTIONS } from '@utils/client/registration/constants';

const ClientRegistration = () => {
  const { dispatchClient, getClient } = useRedux();
  const navigate = useNavigate();
  const location = useLocation();

  const [clientInfo, setClientInfo] = useState(
    !isEmpty(getClient())
      ? getClient()
      : {
          name: '',
          surname: '',
          email: location.state,
          phone: '',
          nationality: '',
          language: '',
          brokerage: '',
          partner: '',
          partnerName: '',
          agentName: '',
          agentEmail: '',
          agentPhone: '',
          terms: false,
          marketing: false,
          newsletter: false
        }
  );

  const [error, setError] = useState({});
  const [agentPhone, setAgentPhone] = useState(clientInfo.agentPhone);
  const [phone, setPhone] = useState(clientInfo.phone);

  const [goToNextPage, setGoToNextPage] = useState(false);

  useEffect(() => {
    if (goToNextPage && isEmpty(error)) {
      dispatchClient(clientInfo);
      navigate('/client/register/request');
    }
    setGoToNextPage(false);
  }, [goToNextPage, error]);

  // Handle input changes
  const handleChange = e => {
    const { name, value } = e.target;
    setClientInfo(prev => ({ ...prev, [name]: value }));
  };

  const handlePhone = (value, countryCode) => {
    const code = countryCode.country.dialCode;
    const phoneNumber = value.replace('+', '').replace(code, '');
    setPhone(phoneNumber);
    console.log('PHONE: ', phoneNumber);
    setClientInfo(prev => ({ ...prev, phone: value }));
  };

  const handleAgentPhone = (value, countryCode) => {
    const code = countryCode.country.dialCode;
    const phoneNumber = value.replace('+', '').replace(code, '');
    setAgentPhone(phoneNumber);
    setClientInfo(prev => ({ ...prev, agentPhone: value }));
  };

  const handleClear = e => {
    if (e.target.name === 'phone') setPhone('');
    if (e.target.name === 'agentPhone') setAgentPhone('');
    setClientInfo(prev => ({ ...prev, [e.target.name]: '' }));
  };

  useEffect(() => {
    if (clientInfo?.brokerage === 'No') {
      setClientInfo(prev => ({ ...prev, partner: '', partnerName: '', agentName: '', agentEmail: '', agentPhone: '' }));
    }
  }, [clientInfo?.brokerage]);

  const handleRegister = () => {
    setError({});
    if (isEmpty(clientInfo?.name)) setError(prev => ({ ...prev, name: 'Please enter a name.' }));
    if (isEmpty(clientInfo?.surname)) setError(prev => ({ ...prev, surname: 'Please enter a surname.' }));
    if (isEmpty(phone)) setError(prev => ({ ...prev, phone: 'Please enter a phone number.' }));
    if (isEmpty(clientInfo?.nationality)) setError(prev => ({ ...prev, nationality: 'Please select a nationality.' }));
    if (isEmpty(clientInfo?.brokerage)) setError(prev => ({ ...prev, brokerage: 'Please select an option.' }));
    if (clientInfo?.brokerage === 'Yes' && isEmpty(clientInfo?.partner))
      setError(prev => ({ ...prev, partner: 'Please select a partner.' }));
    if (clientInfo?.partner === 'Other' && isEmpty(clientInfo?.partnerName))
      setError(prev => ({ ...prev, partnerName: "Please enter a partner's name." }));
    if (clientInfo?.brokerage === 'Yes' && isEmpty(clientInfo?.agentName))
      setError(prev => ({ ...prev, agentName: "Please enter your agent's name." }));
    if (clientInfo?.brokerage === 'Yes' && isEmpty(clientInfo?.agentEmail))
      setError(prev => ({ ...prev, agentEmail: "Please enter your agent's email." }));
    if (clientInfo?.terms === false) setError(prev => ({ ...prev, terms: true }));

    if (!isEmpty(clientInfo.name) && clientInfo.name.includes(' '))
      setError(prev => ({ ...prev, name: 'Please enter only one word.' }));
    if (!isEmpty(phone) && !validator.isMobilePhone(clientInfo.phone))
      setError(prev => ({ ...prev, phone: 'Please enter a valid phone number.' }));
    if (clientInfo?.brokerage === 'Yes' && !isEmpty(clientInfo?.agentEmail) && !isValidEmail(clientInfo?.agentEmail))
      setError(prev => ({ ...prev, agentEmail: 'Please enter a valid email address.' }));
    if (clientInfo?.brokerage === 'Yes' && !isEmpty(agentPhone) && !validator.isMobilePhone(clientInfo.agentPhone))
      setError(prev => ({ ...prev, agentPhone: 'Please enter a valid phone number.' }));

    setGoToNextPage(true);
  };

  useEffect(() => {
    if (!isEmpty(clientInfo?.name)) setError(prev => ({ ...prev, name: '' }));
    if (!isEmpty(clientInfo?.surname)) setError(prev => ({ ...prev, surname: '' }));
    if (!isEmpty(phone)) setError(prev => ({ ...prev, phone: '' }));
    if (!isEmpty(clientInfo?.nationality)) setError(prev => ({ ...prev, nationality: '' }));
    if (!isEmpty(clientInfo?.brokerage)) setError(prev => ({ ...prev, brokerage: '' }));
    if (clientInfo?.brokerage === 'Yes' && !isEmpty(clientInfo?.partner)) setError(prev => ({ ...prev, partner: '' }));
    if (clientInfo?.partner === 'Other' && !isEmpty(clientInfo?.partnerName))
      setError(prev => ({ ...prev, partnerName: '' }));
    if (clientInfo?.brokerage === 'Yes' && !isEmpty(clientInfo?.agentName))
      setError(prev => ({ ...prev, agentName: '' }));
    if (clientInfo?.brokerage === 'Yes' && !isEmpty(clientInfo?.agentEmail))
      setError(prev => ({ ...prev, agentEmail: '' }));
    if (clientInfo?.terms === true) setError(prev => ({ ...prev, terms: false }));
  }, [clientInfo]);

  return (
    <div className='client-data-container'>
      <Header shouldDisplay isDark />
      <div className='client-data-wrapper'>
        <img src={image} className='client-data-wrapper-background-image' />
        <div className='content-wrapper'>
          <div className='back-button'>
            <LinkButton icon='navigation/arrow-left' text='Back' onClickFn={() => navigate('/client')} />
          </div>
          <div className='client-data'>
            <div className='client-data-header'>
              <span className='client-data-header-title'>Design Unique Experiences</span>
              <span className='client-data-header-description'>
                Picture private gardens, ample spaces, and exclusive comfort in a unique location.
              </span>
            </div>
            <div className='client-data-body'>
              <TextInput
                label='Name'
                name='name'
                icon='action/user'
                value={clientInfo.name}
                onChangeFn={handleChange}
                clearFn={() => handleClear({ target: { name: 'name' } })}
                required
                errorMessage={error.name}
              />
              <TextInput
                label='Surname'
                name='surname'
                icon='action/user'
                value={clientInfo.surname}
                onChangeFn={handleChange}
                clearFn={() => handleClear({ target: { name: 'surname' } })}
                required
                errorMessage={error.surname}
              />
              <TextInput label='E-mail' name='email' icon='action/email' lock value={clientInfo.email} required />
              <InputPhone
                name='phone'
                value={`${clientInfo.phone}`}
                onChangeFn={handlePhone}
                clearFn={() => handleClear({ target: { name: 'phone' } })}
                required
                errorMessage={error.phone}
              />
              <div className='dropdown'>
                <Dropdown
                  label='Nationality'
                  placeholder={'Select nationality'}
                  options={NATIONALITY_OPTIONS}
                  onChange={item => {
                    setClientInfo(prev => ({ ...prev, nationality: item }));
                  }}
                  value={clientInfo.nationality}
                  errorMessage={error.nationality}
                ></Dropdown>
                <Dropdown
                  label='Language'
                  placeholder={'Select language'}
                  options={LANGUAGES_OPTIONS}
                  onChange={item => {
                    setClientInfo(prev => ({ ...prev, language: item }));
                  }}
                  value={clientInfo.language}
                  optional
                ></Dropdown>
              </div>
              <div className='brokerage'>
                <span className='brokerage-title'>Brokerage</span>
                <div className='brokerage-list'>
                  {['Yes', 'No'].map(label => {
                    return (
                      <RadioButton
                        name='brokerage'
                        id={label}
                        label={label}
                        onSelect={() => {
                          setClientInfo(prev => ({ ...prev, brokerage: label }));
                        }}
                        value={clientInfo.brokerage !== '' ? clientInfo.brokerage : false}
                        error={error.brokerage || false}
                      />
                    );
                  })}
                </div>
                <span className='brokerage-error-message'>{error.brokerage}</span>
              </div>
              {clientInfo.brokerage === 'Yes' && (
                <>
                  <Dropdown
                    label='Partner'
                    placeholder={'Select a partner'}
                    options={PARTNERS_OPTIONS}
                    onChange={item => {
                      setClientInfo(prev => ({ ...prev, partner: item }));
                    }}
                    value={clientInfo.partner}
                    errorMessage={error.partner}
                  ></Dropdown>
                  {clientInfo.partner === 'Other' && (
                    <TextInput
                      label='Partner name'
                      name='partnerName'
                      icon='action/user'
                      value={clientInfo.partnerName}
                      onChangeFn={handleChange}
                      clearFn={() => handleClear({ target: { name: 'partnerName' } })}
                      required
                      errorMessage={error.partnerName}
                    />
                  )}
                  <TextInput
                    label='Agent name'
                    name='agentName'
                    icon='action/user'
                    value={clientInfo.agentName}
                    onChangeFn={handleChange}
                    clearFn={() => handleClear({ target: { name: 'agentName' } })}
                    required
                    errorMessage={error.agentName}
                  />
                  <TextInput
                    label='Agent e-mail'
                    name='agentEmail'
                    icon='action/email'
                    value={clientInfo.agentEmail}
                    onChangeFn={handleChange}
                    clearFn={() => handleClear({ target: { name: 'agentEmail' } })}
                    required
                    errorMessage={error.agentEmail}
                  />
                  <InputPhone
                    name='agentPhone'
                    value={`${clientInfo.agentPhone}`}
                    onChangeFn={handleAgentPhone}
                    clearFn={() => handleClear({ target: { name: 'agentPhone' } })}
                    errorMessage={error.agentPhone}
                  />
                </>
              )}
            </div>
            <div className='client-data-footer'>
              <div className='client-data-footer-list'>
                <Checkbox
                  label={
                    <>
                      I accept the{' '}
                      <Link
                        to='/terms-and-conditions'
                        target='_blank'
                        rel='noopener noreferrer'
                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                      >
                        terms and conditions
                      </Link>{' '}
                      and{' '}
                      <Link
                        to='/privacy-policy'
                        target='_blank'
                        rel='noopener noreferrer'
                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                      >
                        privacy policy.
                      </Link>{' '}
                    </>
                  }
                  onSelect={() => setClientInfo(prev => ({ ...prev, terms: true }))}
                  onDeselect={() => setClientInfo(prev => ({ ...prev, terms: false }))}
                  value={clientInfo.terms}
                  error={error.terms}
                ></Checkbox>
                {clientInfo.brokerage !== 'Yes' && (
                  <Checkbox
                    label='I want to receive marketing communications.'
                    onSelect={() => setClientInfo(prev => ({ ...prev, marketing: true }))}
                    onDeselect={() => setClientInfo(prev => ({ ...prev, marketing: false }))}
                    value={clientInfo.marketing}
                  ></Checkbox>
                )}
                {clientInfo.brokerage !== 'Yes' && (
                  <Checkbox
                    label='I want to subscribe to the newsletter.'
                    onSelect={() => setClientInfo(prev => ({ ...prev, newsletter: true }))}
                    onDeselect={() => setClientInfo(prev => ({ ...prev, newsletter: false }))}
                    value={clientInfo.newsletter}
                  ></Checkbox>
                )}
              </div>
              <div className='client-data-footer-button'>
                <Button primary text='Register' onClickFn={handleRegister} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientRegistration;
