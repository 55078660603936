import { createSlice } from '@reduxjs/toolkit';

const initialState = 0;

const pricingSlice = createSlice({
  name: 'pricing',
  initialState,
  reducers: {
    setPrice: (state, action) => {
      return action.payload;
    },
    resetPrice: state => {
      return initialState;
    }
  }
});

const pricingAction = pricingSlice.actions;
export { pricingSlice, pricingAction };
