import { useState } from 'react';
// styles
import './PasswordInput.scss';
// utils
import { SVG } from '@assets/svg';

const PasswordInput = ({ label, name, icon, placeholder, value, onChangeFn, required, errorMessage }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className='passwordInput'>
      <span className='label'>{label}</span>
      <div className='input-wrapper'>
        <SVG icon={icon} customCSS='left-icon' />
        <input
          type={showPassword ? 'text' : 'password'}
          className={`input${errorMessage ? ' error' : ''}`}
          name={name}
          placeholder={placeholder}
          value={value}
          {...(onChangeFn && { onChange: onChangeFn })}
          {...(required && { required: true })}
        />
        <div className='toggle-password' onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? <SVG icon='action/eye-off' /> : <SVG icon='action/eye' />}
        </div>
      </div>
      <span className='errorMessage'>{errorMessage}</span>
    </div>
  );
};

export default PasswordInput;
