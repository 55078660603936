import React, { useState, useRef, useEffect } from 'react';

const OTPInput = ({
  length = 6,
  onComplete,
  containerStyle,
  inputNormalStyle,
  inputErrorStyle,
  errorMessage,
  onChange
}) => {
  const [otp, setOtp] = useState(Array(length).fill(''));
  const inputRefs = useRef([]);

  const handleChange = (value, index) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < length - 1) {
      inputRefs.current[index + 1].focus();
    }

    if (newOtp.every(digit => digit !== '')) {
      onComplete?.(newOtp.join(''));
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      onChange();
      e.preventDefault();
      const newOtp = [...otp];
      newOtp[index] = '';
      setOtp(newOtp);
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }

    if (e.key === 'ArrowRight') {
      if (index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }

    if (e.key === 'ArrowLeft') {
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handlePaste = e => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text').slice(0, length).split('');
    const newOtp = [...otp];

    pastedData.forEach((value, i) => {
      if (i < length) {
        newOtp[i] = value;
      }
    });

    setOtp(newOtp);
    inputRefs.current[pastedData.length - 1]?.focus();

    if (newOtp.every(digit => digit !== '')) {
      onComplete?.(newOtp.join(''));
    }
  };

  const handleFocus = index => {
    setTimeout(() => {
      const input = inputRefs.current[index];
      if (input) {
        input.select();
      }
    }, 20);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
      <div className={containerStyle} onPaste={handlePaste}>
        {otp.map((value, index) => (
          <input
            key={index}
            type='text'
            value={value}
            onChange={e => {
              handleChange(e.target.value.slice(-1), index);
              onChange();
            }}
            onKeyDown={e => handleKeyDown(e, index)}
            ref={el => (inputRefs.current[index] = el)}
            maxLength={1}
            className={errorMessage ? inputErrorStyle : inputNormalStyle}
            onFocus={() => {
              handleFocus(index);
            }}
          />
        ))}
      </div>
      <span className='errorMessage'>{errorMessage}</span>
    </div>
  );
};

export default OTPInput;
