import { formsActions } from './forms-slice';
import { getForms } from '../../services/forms';

export const setForm = async ({ dispatch, additionalData }) => {
  try {
    const response = await getForms();
    response.map(r => {
      if (r.id === 'journey') {
        const { macroplots, plots } = additionalData;
        const formmatedResponse = { ...r };
        if (macroplots && plots) {
          formmatedResponse.steps[0].questions[0].answers = [{ items: macroplots }];
          formmatedResponse.steps[0].questions[1].answers = [{ items: plots }];
        }
        return dispatch(formsActions.setForm(formmatedResponse));
      } else {
        return dispatch(formsActions.setForm(r));
      }
    });
  } catch (error) {
    console.log('ERROR');
  } finally {
  }
};
