import { useState } from 'react';
// styles
import './Accordion.scss';
import { SVG } from '@assets/svg';

const Accordion = ({ label, description, content }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div className='accordion__container' onClick={handleClick}>
      {!open && (
        <div className='accordion__container-header-line'>
          <div className='accordion__container-header-close'>
            <span>{label}</span>
            <SVG className='chevron' icon={'navigation/chevron-down'} />
          </div>
          <span>{description}</span>
        </div>
      )}
      {open && (
        <div className='accordion__container-open'>
          <div className='accordion__container-header-line'>
            <div className='accordion__container-header-open'>
              <span>{label}</span>
              <SVG className='chevron' icon={'navigation/chevron-up'} />
            </div>
            <span>{description}</span>
          </div>
          <div className='accordion__container-list'>
            {content.map(item => {
              return item.title ? (
                <div className='accordion__container-item'>
                  <span className='item-title'>{item.title}</span>
                </div>
              ) : (
                <div className='accordion__container-item'>
                  <span className='item-label'>{item.label}</span>
                  <span className='item-value'>{item.value}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Accordion;
