import axios from 'axios';
import { getFullEndpoint } from '../utils/endpoints/functions';

export const login = async credentials => {
  const HEADERS = {
    accept: '*/*'
  };
  const requestObj = {
    method: 'post',
    url: getFullEndpoint({ microservice: 'salesmanager', service: 'login' }),
    headers: HEADERS,
    data: credentials
  };

  const response = await axios.request(requestObj);
  // const response = await axios.request(requestObj).catch(error => handleError(error));
  return response;
};
