import React from 'react';
import { SVG } from '@assets/svg';
//hooks
import { useNavigate } from 'react-router-dom';
//styling
import './header.scss';
//frameworks
import { useTranslation } from 'react-i18next';

const Header = ({ shouldDisplay, isDark, logoOnly, appearDashboard }) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const changeLanguage = () => {
    if (i18n.language === 'pt') {
      i18n.changeLanguage('en');

    } else {
      i18n.changeLanguage('pt');
    }
  };

  const goToLogin = () => {
    navigate('/sales-manager/login');
  };

  return (
    <>
      {shouldDisplay && (
        <div className='header-container'>
          {isDark ? <SVG icon='logo/bespokeliving' /> : <SVG icon='logo/bespokeliving_white' />}
          {!logoOnly && <div className={`login-section${isDark ? '-dark' : ''}`}>
            <div className='language-wrapper'>
            {appearDashboard && <span onClick={()=> {navigate('/dashboard')}} className='language-label'>Dashboard</span>}
              <SVG icon='action/globe' customCSS={'globe-icon'} />
              <span onClick={changeLanguage} className='language-label'>{i18n.language.toUpperCase()}</span>
            </div>
            <div className='user-wrapper' onClick={goToLogin}>
              <SVG icon='action/user' customCSS={'user-icon'} />
            </div>
          </div>}
        </div>
      )}
    </>
  );
};

export default Header;
