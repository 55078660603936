import { configureStore } from '@reduxjs/toolkit';

import { trackingSlice } from './tracking/tracking-slice';
import { formsSlice } from './forms/forms-slice';
import { macroplotsSlice } from './macroplots/macroplots-slice';
import { plotsSlice } from './plots/plots-slice';
import { housesSlice } from './houses/houses-slice';
import { imagesSlice } from './image/image-slice';
import { modulesSlice } from './modules/modules-slice';
import { houseTypesSlice } from './houseTypes/houseTypes-slice';
import { filtersSlice } from './filters/filters-slice';
import { packsSlice } from './packs/packs-slice';
import { modalsSlice } from './modals/modals-slice';
import { notificationSlice } from './notification/notification-slice';
import { formStateSlice } from './formState/formState-slice';
import { partnersSlice } from './partners/partners-slice';
import { homepageSlice } from './homepage/homepage-slice';
import { authSlice } from './auth/auth-slice';
import { pricingSlice } from './pricing/pricing-slice';
import { projectSubmittedSlice } from './projectSubmitted/projectSubmitted-slice';
import { currentProjectSlice } from './project/project-slice';
import { clientSlice } from './client/client-slice';

// Import reducers to include on store
const store = configureStore({
  reducer: {
    tracking: trackingSlice.reducer,
    forms: formsSlice.reducer,
    macroplots: macroplotsSlice.reducer,
    plots: plotsSlice.reducer,
    houses: housesSlice.reducer,
    images: imagesSlice.reducer,
    modules: modulesSlice.reducer,
    houseTypes: houseTypesSlice.reducer,
    packs: packsSlice.reducer,
    filters: filtersSlice.reducer,
    modals: modalsSlice.reducer,
    partners: partnersSlice.reducer,
    notification: notificationSlice.reducer,
    formState: formStateSlice.reducer,
    homepage: homepageSlice.reducer,
    auth: authSlice.reducer,
    pricing: pricingSlice.reducer,
    projectSubmitted: projectSubmittedSlice.reducer,
    currentProject: currentProjectSlice.reducer,
    client: clientSlice.reducer
  }
});

export default store;
