import React from 'react';
import PropTypes from 'prop-types';
import './Button.scss';

const Button = ({ primary, secondary, ghost, destructive, isDark, text, number, onClickFn, ...props }) => {
  // Ensure that only one "type" is specified
  const types = [primary, secondary, ghost].filter(Boolean);
  if (types.length > 1) {
    console.error('Button component should only accept one type: primary, secondary or ghost');
    return null;
  }

  const getType = (primary, secondary, ghost) => {
    if (primary) return 'button';
    else if (secondary) return 'button-secondary';
    else if (ghost) return 'button-ghost';
    else return '';
  };

  const getKind = destructive => {
    if (destructive) return '-destructive';
    else return '';
  };

  const getMode = isDark => {
    if (isDark) return '-dark';
    else return '';
  };

  // Determine the button classes based on type (primary, secondary, ghost) and kind (default, destructive)
  const buttonClass = `
    ${getType(primary, secondary, ghost)?.trim()}${getMode(isDark)?.trim()}${getKind(destructive)} 
  `;

  return (
    <div className={buttonClass?.trim()} onClick={onClickFn} {...props}>
      {/* If an icon is provided, render it */}
      {/* {icon && <img src={icon} alt="button-icon" className="button__icon" />} */}
      {/* <DynamicIcon icon={"collapse"} /> */}
      {text}
      <span>{number}</span>
    </div>
  );
};

// Define the default prop values
Button.defaultProps = {
  primary: false,
  secondary: false,
  ghost: false,
  destructive: false,
  text: 'Button',
  number: ''
};

// Define prop types for validation
Button.propTypes = {
  primary: PropTypes.bool, // Ensures the prop is a boolean
  secondary: PropTypes.bool, // Ensures the prop is a boolean
  ghost: PropTypes.bool, // Ensures the prop is a boolean
  destructive: PropTypes.bool, // Ensures the prop is a boolean
  text: PropTypes.string.isRequired // Ensures the prop is a string and required
};

export default Button;
