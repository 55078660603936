import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
// components
import IconButton from '@components/ui/Buttons/IconButton/IconButton';
// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
// styles
import './BasicModal.scss';

const BasicModal = ({ currentStepNr, modals, onClose }) => {
  const [renderContent, setRenderContent] = useState(false);

  return (
    <Swiper
      watchSlidesProgress={true} // enables to see next or previous slide
      slidesPerView={'auto'} // max os slides per view
      centeredSlides={true}
      spaceBetween={40}
      pagination={{
        clickable: true,
        dynamicBullets: false
      }}
      modules={[Navigation, Pagination]}
      className='basic-modal-swiper'
      onSlideChange={e => console.log('slide change', e)}
    >
      {modals?.map(modal => {
        const paragraphs = modal?.bodycopy.split(/\n\n/);
        return (
          <SwiperSlide className='swiper-slide'>
            <div className='basic-modal-slide-content'>
              <div className='img'>
                <img
                  onLoad={() => {
                    setTimeout(() => {
                      setRenderContent(true);
                    }, 200);
                  }}
                  src={modal?.image}
                  alt=''
                />
              </div>

              {renderContent && (
                <div className='basic-modal-slide-text'>
                  <div className='basic-modal-text-header'>
                    <IconButton
                      className='basic-modal-close'
                      size={'small'}
                      onImageBg={false}
                      icon={'semantic/close'}
                      onClickFn={onClose}
                    />
                  </div>
                  <div className='basic-modal-text-body'>
                    <span className='basic-modal-title'>{modal?.title}</span>
                    <span className='basic-modal-subtitle'>{modal?.subtitle}</span>
                    {currentStepNr === 1 ? (
                      <div className='markdown-content-big'>
                        {paragraphs.map((paragraph, index) => {
                          return (
                            <div key={index}>
                              <ReactMarkdown>{paragraph.replace(/\n/g, '  \n')}</ReactMarkdown>
                              {index < paragraphs.length - 1 && <br />}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div className='markdown-content'>
                        {paragraphs.map((paragraph, index) => {
                          return (
                            <div key={index}>
                              <ReactMarkdown>{paragraph.replace(/\n/g, '  \n')}</ReactMarkdown>
                              {index < paragraphs.length - 1 && <br />}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default BasicModal;

/* <div className='left-button'>
<IconButton size={'default'} onImageBg={true} icon={'navigation/arrow-left'} onClickFn={prevImage} />
</div> */

/*<div className='right-button'>
        <IconButton size={'default'} onImageBg={true} icon={'navigation/arrow-right'} onClickFn={nextImage} />
      </div> */
