import { FormProvider } from 'react-hook-form';
import Review from '../../journey/Review';
import Journey from '../../journey/Journey';

import './PanelContent.scss';
import { isEmpty } from '@utils/functions';

const PanelContent = ({
  formMethods,
  steps,
  currentStepNr,
  currentStepId,
  formState,
  REVIEW_DATA,
  setCustomFormStep,
  setShowFiltersPanel,
  inlineMessage,
  setProjectSubmittedPageOpen
}) => {
  const { handleSubmit, watch, control } = formMethods;
  const watcher = watch();

  return (
    <div className='panel-content'>
      {steps.length !== 0 && !isEmpty(currentStepId) && !isEmpty(currentStepNr) && (
        <form onSubmit={handleSubmit(data => console.log(data))}>
          {currentStepId === 'review' && !isEmpty(watcher) ? (
            <Review
              formState={formState}
              reviewData={REVIEW_DATA}
              journeyObj={steps[currentStepNr]}
              setCustomFormStep={setCustomFormStep}
              setProjectSubmittedPageOpen={setProjectSubmittedPageOpen}
            >
              <Journey
                // componentName={stepToRender}
                currentStep={steps[currentStepNr]}
                control={control}
                currentStepId={currentStepId}
                currentStepNr={currentStepNr}
                setShowFiltersPanel={setShowFiltersPanel}
                inlineMessage={inlineMessage}
              />
            </Review>
          ) : (
            <Journey
              // componentName={stepToRender}
              currentStep={steps[currentStepNr]}
              control={control}
              currentStepId={currentStepId}
              currentStepNr={currentStepNr}
              setShowFiltersPanel={setShowFiltersPanel}
              inlineMessage={inlineMessage}
            />
          )}
        </form>
      )}
    </div>
  );
};

export default PanelContent;
