import Cookies from 'js-cookie';

// Check if sessionId cookie exists
export const isAuthenticated = () => {
  return !!Cookies.get('sessionId') && !!Cookies.get('salesManager');
};

// Set sessionId cookie after successful login
export const setSession = ({ sessionId, expiresIn }) => {
  // convert seconds to minutes
  // const expiresInMinutes = 60 * expiresIn;
  // const expiresInDays = new Date(new Date().getTime() + expiresInMinutes);

  const date = new Date();
  date.setTime(date.getTime() + expiresIn * 1000);

  console.log(date);

  Cookies.set('sessionId', sessionId, { expires: date });
};

export const setSalesManager = ({ username, expiresIn }) => {
  const date = new Date();
  date.setTime(date.getTime() + expiresIn * 1000);
  Cookies.set('salesManager', username, { expires: date });
};

// Remove sessionId cookie on logout
export const clearSession = () => {
  Cookies.remove('sessionId');
};
