import { createSlice } from '@reduxjs/toolkit';

const packsSlice = createSlice({
  name: 'packs',
  initialState: {},
  reducers: {
    setPacks: (state, action) => {
      return [...action.payload];
    }
  }
});

const packsAction = packsSlice.actions;
export { packsSlice, packsAction };
