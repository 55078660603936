// components
import LinkButton from '@components/ui/Buttons/LinkButton/LinkButton';
// styles
import './AdditionalServicesCard.scss';

const AdditionalServicesCard = ({ card, onClickFn }) => {
  return (
    <div className='additional-services-card'>
      <span className='additional-services-card-title'>{card.title}</span>
      <span className='additional-services-card-description'>{card.description}</span>
      <LinkButton icon='navigation/circle-arrow-right' text={card.linkLabel} onClickFn={onClickFn} />
    </div>
  );
};

export default AdditionalServicesCard;
