import { createSlice } from '@reduxjs/toolkit';

const homepageSlice = createSlice({
  name: 'homepage',
  initialState: {},
  reducers: {
    setHomepageContent: (state, action) => {
      return [...action.payload];
    }
  }
});

const homepageAction = homepageSlice.actions;
export { homepageSlice, homepageAction };
