import { createSlice } from '@reduxjs/toolkit';

const modalsSlice = createSlice({
  name: 'modals',
  initialState: {},
  reducers: {
    setModals: (state, action) => {
      return [...action.payload];
    }
  }
});

const modalsAction = modalsSlice.actions;
export { modalsSlice, modalsAction };
