import axios from 'axios';
import { getFullEndpoint } from '../utils/endpoints/functions';

export const getClientDetails = async (email, token) => {
  const HEADERS = {
    accept: '*/*',
    Authorization: `Bearer ${token}`
  };

  const requestObj = {
    method: 'get',
    url: `${getFullEndpoint({ microservice: 'client', service: 'getDetails' })}/${email}`,
    headers: HEADERS
  };

  const response = await axios.request(requestObj);
  return response;
};

export const registerClient = async (data, token) => {
  const HEADERS = {
    accept: '*/*',
    Authorization: `Bearer ${token}`
  };

  const requestObj = {
    method: 'post',
    url: `${getFullEndpoint({ microservice: 'client', service: 'registerClient' })}`,
    headers: HEADERS,
    data
  };

  const response = await axios.request(requestObj);
  return response.data;
};
