import { modulesAction } from './modules-slice';
import { getModules } from '@services/modules';

export const setModules = async dispatch => {
  try {
    const response = await getModules();
    dispatch(modulesAction.setModules(response));
  } catch (error) {
    console.log('ERROR');
  } finally {
  }
};
