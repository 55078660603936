import { useRef, useState, useEffect } from 'react';
// hooks
import useRedux from '@hooks/useRedux';
import { useNavigate } from 'react-router-dom';
// components
import Button from '../../ui/Buttons/Button/Button';
import IconButton from '@components/ui/Buttons/IconButton/IconButton';
import { Popper } from '@mui/base/Popper';
import MenuItem from '@components/ui/MenuItem/MenuItem';
// utils
import { DIALOG_TYPE } from '@utils/enums';
// styles
import './PanelFooter.scss';

// Custom hook to detect click outside of a given element
const useClickOutside = (ref, handler, exceptionRef) => {
  useEffect(() => {
    const handleClickOutside = event => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !(exceptionRef?.current && exceptionRef.current.contains(event.target))
      ) {
        handler();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, handler, exceptionRef]);
};

const PanelFooter = ({ formState, currentStepNr, currentStepId, setNextFormStep, setProjectSubmittedPageOpen }) => {
  const { dispatchSetDialog, PRICING } = useRedux();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const popperRef = useRef(null); // Reference to the popper content
  const buttonRef = useRef(null); // Reference to the toggle button
  const navigate = useNavigate();

  const handleClick = event => {
    if (anchorEl) {
      setAnchorEl(null); // Close popper if it's open
    } else {
      setAnchorEl(event.currentTarget); // Open popper if it's closed
    }
  };

  // Use the custom hook to close the popper when clicking outside, except for the toggle button
  useClickOutside(popperRef, () => setAnchorEl(null), buttonRef);

  const journeyFooter = (
    <>
      <IconButton ref={buttonRef} icon={'action/grid'} onClickFn={handleClick} />
      <Popper id={id} open={open} anchorEl={anchorEl} placement='top-start' style={{ zIndex: 9999 }}>
        <div className='more-menu' ref={popperRef}>
          {/* consult price only available on step Exteriors and following */}
          {!['plot', 'layout'].includes(currentStepId) && (
            <MenuItem
              text='Consult Price'
              onClickFn={() => {
                PRICING.update({ formState });
                dispatchSetDialog({ open: true, type: 'image', id: DIALOG_TYPE.ConsultPrice });
              }}
            />
          )}
          <MenuItem
            text='Save and Exit'
            onClickFn={() => {
              dispatchSetDialog({ open: true, type: 'default', id: DIALOG_TYPE.SaveExit });
              handleClick();
            }}
          />
          <MenuItem text='Preferences' onClickFn={() => {}} />
        </div>
      </Popper>
      <Button
        primary
        text={'Next'}
        onClickFn={() => {
          if (currentStepId === 'interiors') PRICING.update({ formState });
          setNextFormStep(currentStepNr++);
        }}
      />
    </>
  );

  const reviewFooter = (
    <>
      <IconButton ref={buttonRef} icon={'action/grid'} onClickFn={handleClick} />
      <Popper id={id} open={open} anchorEl={anchorEl} placement='top-start' style={{ zIndex: 9999 }}>
        <div className='more-menu' ref={popperRef}>
          <MenuItem
            text='Save and Exit'
            onClickFn={() => {
              dispatchSetDialog({ open: true, type: 'default', id: DIALOG_TYPE.SaveExit });
              handleClick();
            }}
          />
          <MenuItem text='Preferences' />
        </div>
      </Popper>
      <div className='review-buttons'>
        <Button
          secondary
          text={'Save Project'}
          onClickFn={() => {
            dispatchSetDialog({ open: true, type: 'default', id: DIALOG_TYPE.SaveProject });
          }}
        />
        <Button primary text={'Confirm interest'} onClickFn={setProjectSubmittedPageOpen} />
      </div>
    </>
  );

  return <div className='form-footer'>{currentStepId === 'review' ? reviewFooter : journeyFooter}</div>;
};

export default PanelFooter;
