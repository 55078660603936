import { useState, useEffect } from 'react';
import './Checkbox.scss';

const Checkbox = ({ label, disabled, onSelect, onDeselect, value, error }) => {
  const [isChecked, setIsChecked] = useState(false);

  const CheckboxClass = `
  ${label ? 'checkbox-item' : 'checkbox-item-no-label'}
  `;

  useEffect(() => {
    setIsChecked(value);
  }, [value]);

  useEffect(() => {
    if (isChecked && disabled) {
      setIsChecked(false);
      onDeselect();
    }
  }, [disabled]);

  return (
    <div className={`${CheckboxClass.trim()}`}>
      <input
        type='checkbox'
        className={error ? 'error' : ''}
        disabled={disabled}
        checked={isChecked}
        onChange={() => {
          if (isChecked) {
            onDeselect();
          } else {
            onSelect();
          }
          setIsChecked(!isChecked);
        }}
      />
      <label className={`checkbox-item-label ${isChecked ? ' checked' : ' '} ${disabled ? ' disabled' : ' '}`}>
        {label}
      </label>
    </div>
  );
};

// Checkbox.defaultProps = {
//   label: ' ',
//   disabled: false
// };

export default Checkbox;
