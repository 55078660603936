import './Information.scss';

const Information = ({ children, onClickFn }) => {
  return (
    <div className='information' onClick={onClickFn}>
      {children}
    </div>
  );
};

export { Information };
