import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
//styles
import './homepage.scss';
//components
import VideoComponent from './VideoComponent';
import Header from '@components/ui/Header/Header';
//utils
import Button from '@components/ui/Buttons/Button/Button';
import imagem from './first_frame.jpg';

const Homepage = ({ homepageContent }) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const content = homepageContent?.[0];
  const buttonType = content?.actionButton?.type?.split('-')?.[0];
  const buttonMode = content?.actionButton?.type?.split('-')?.[1];

  const navigate = useNavigate();

  const goToClientPage = () => {
    navigate('/client');
  };

  //TODO i18n

  return homepageContent && homepageContent.length > 0 ? (
    <div className='homepage-container'>
      <Header shouldDisplay appearDashboard/>
      <div className='content-section'>
        <div className='title-wrapper'>
          <span className='homepage-title1'>{content?.title1}</span>
          <span className='homepage-title2'>{content?.title2}</span>
        </div>
        <div>
          <Button
            primary={buttonType === 'PRIMARY'}
            secondary={buttonType === 'SECONDARY'}
            ghost={buttonType === 'GHOST'}
            isDark={buttonMode === 'DARK'}
            text={content?.actionButton?.label}
            onClickFn={goToClientPage}
          />
        </div>
      </div>
      {!isVideoLoaded && <img className='background-image' src={imagem} />}
      <VideoComponent videoUrl={content?.backgroundVideo} setVideoIsReady={setIsVideoLoaded} />
    </div>
  ) : (
    <>
    </>
  );
};

export default Homepage;
